import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
import './styles/Statistics.css';

/**
 * Statistics component that provides key statistics about the dual study programs, the number of dual students, and partner companies of DHBW.
 *
 * @returns {JSX.Element} The rendered Statistics component.
 */
const Statistics = () => {
  return (
    <div className='statistics padding-container padding-top'>
      <Helmet>
        <meta
          name='description'
          content='Erfahren Sie mehr über die dualen Studiengänge, die Anzahl der dualen Studenten und die Partnerunternehmen der DHBW.'
        />
        <meta
          name='keywords'
          content='Duale Studiengänge, DHBW, duale Studenten, Partnerunternehmen, Statistik'
        />
        <meta
          property='og:title'
          content='Duale Studiengänge und Partnerunternehmen - DHBW Statistik'
        />
        <meta
          property='og:description'
          content='Erfahren Sie mehr über die dualen Studiengänge, die Anzahl der dualen Studenten und die Partnerunternehmen der DHBW.'
        />
        <meta property='og:url' content='imbit-n3xt.com/' />
        <meta
          name='twitter:title'
          content='Duale Studiengänge und Partnerunternehmen - DHBW Statistik'
        />
        <meta
          name='twitter:description'
          content='Erfahren Sie mehr über die dualen Studiengänge, die Anzahl der dualen Studenten und die Partnerunternehmen der DHBW.'
        />
      </Helmet>
      <div className='statistics__box'>
        <p className='statistics__number text--xl'>100 +</p>
        <p className='statistics__description'>
          <FormattedMessage
            id='statistics.dualProgramsDesc'
            defaultMessage='Duale DHBW-Studiengänge'
          />
        </p>
      </div>
      <div className='statistics__box'>
        <p className='statistics__number text--xl'>32.000 +</p>
        <p className='statistics__description'>
          <FormattedMessage
            id='statistics.dualStudentsDesc'
            defaultMessage='Duale DHBW-Studenten'
          />
        </p>
      </div>
      <div className='statistics__box'>
        <p className='statistics__number text--xl'>9.000 +</p>
        <p className='statistics__description'>
          <FormattedMessage
            id='statistics.partnerCompaniesDesc'
            defaultMessage='DHBW-Partnerunternehmen'
          />
        </p>
      </div>
    </div>
  );
};

export default Statistics;
