import React from 'react';
import { FormattedMessage } from 'react-intl';
import LissabonImage from '../media/Lissabon.webp';
import MelbourneImage from '../media/Melbourne.webp';
import ParisImage from '../media/Paris.webp';
import SingaporeImage from '../media/Singapore.webp';
import SydneyImage from '../media/Sydney.webp';
import SydneyOperaImage from '../media/SydneyOpera.webp';
import ValenciaImage from '../media/Valencia.webp';

export default [
  {
    img: ParisImage, // URL
    city: 'Paris',
    company: 'Atos',
    abteilung: 'Innovation Center',
    name: 'Mar-Elisa Ahrensmeyer',
    standort: (
      <FormattedMessage
        id='bericht1.standort'
        defaultMessage='Gutes Essen und sehr viel Fashion. Viele internationale Studierende, Gutes Abend und Nachtleben. Es gibt sehr viele schöne Museen und das Künstlerviertel in Montmatre ist sehr bekannt.'
      />
    ),
    gelernt: (
      <FormattedMessage
        id='bericht1.gelernt'
        defaultMessage='Dadurch das ich im Headquarter war und viele Leute im Büro, hatte ich die Möglickeit die Zusammenarbeit vor Ort kennenzulernen. Im Vergleich zum Homeoffice konnte ich mein Französisch deutlich verbessern, da ich durchgehend im Austausch mit Kolleg:innen war.'
      />
    ),
    erlebnisse: (
      <FormattedMessage
        id='bericht1.erlebnisse'
        defaultMessage='Am besten kann man im Sommer das gute Wetter am Abend an der Seine mit einem gutem Picknick und wunderschönen Sonnenuntergang ausklingen lassen. Viele Franzosen bringen sogar Käse und Wurstplatte so wie Weingläser mit.'
      />
    ),
    tipps: (
      <FormattedMessage
        id='berich1.tipps'
        defaultMessage='Es ist von Vorteil schon etwas französisch sprechen zu können, nicht nur für die Arbeit sondern auch für das Leben in Paris. Das Schloss in Versailles ist sehr zu empfehlen, allgemein hat Paris sehr viel zu bieten. PS: die meisten Museen sind für Personen unter 26 Jahren kostenlos.'
      />
    ),
  },
  {
    img: LissabonImage,
    city: <FormattedMessage id='bericht2.city' defaultMessage='Lissabon' />,
    company: 'EY',
    abteilung: 'Technology Risk',
    name: 'Frederic Schulenberg',
    standort: (
      <FormattedMessage
        id='bericht2.standort'
        defaultMessage='In erster Linie wollte ich von den vielen jungen und gut ausgebildeten Mitarbeitern dort lernen. Gerade in innovativen Feldern haben einige Firmen mittlerweile eine Niederlassung in Portugal. Darüber hinaus ist aber auch das Land an sich sehr schön, mit angenehmem Klima, guter Küche und dem Meer direkt vor der Tür kam so auch ab und zu Urlaubsfeeling auf.'
      />
    ),
    gelernt: (
      <FormattedMessage
        id='bericht2.gelernt'
        defaultMessage='An den Umgang und das Zusammenarbeiten mit Menschen aus Portugal musste ich mich erstmal gewöhnen. Im Vergleich zu Deutschland geht man miteinander deutlicher herzlicher um, arbeitet permanent zusammen als Team und unternimmt nach der Arbeit noch etwas. Aber auch die Arbeitsweise hat sich aufgrund der deutlich kleineren Abteilung von der Deutschen unterschieden. '
      />
    ),
    erlebnisse: (
      <FormattedMessage
        id='bericht2.erlebnisse'
        defaultMessage='Die Aufnahme im Team sowohl auf als auch neben der Arbeit war definitiv das Beste. Ich wurde dort direkt Teil des Teams und konnte auf den dortigen Projekten mitarbeiten. Neben dem Beruf bekam ich aber auch direkt Tickets für Fußballspiele, Tipps fürs Wochenende und bin abends noch zusammen mit Kollegen etwas essen gegangen.'
      />
    ),
    tipps: (
      <FormattedMessage
        id='bericht2.tipps'
        defaultMessage='Um neben der Arbeit auch noch Portugal zu erkunden, sollte man sich unbedingt übers Wochenende mal einen Mietwagen nehmen, um in die Algarve zu fahren. Dort machen die meisten Portugiesen im Sommer Urlaub, da es dort noch schönerer Strände und Natur gibt. In Lissabon selbst lohnen sich die EFahrräder, die kann man für 2 € am Tag immer wieder für 45 Minuten ausleihen.'
      />
    ),
  },
  {
    img: SydneyImage, // URL
    city: 'Sydney',
    company: 'Döhler',
    abteilung: 'SAP Inhouse Consulting Roll-Out',
    name: 'Lea-Sophia Balbach',
    standort: (
      <FormattedMessage
        id='bericht3.standort'
        defaultMessage='Ich habe mich für diesen Standort entschieden, weil er sich am interessantesten angehört hat. Die Zeit in Australien zu verbringen und dort ein Roll-Out zu unterstützen fand ich sehr ansprechend. '
      />
    ),
    gelernt: (
      <FormattedMessage
        id='bericht3.gelernt'
        defaultMessage='Ich habe gerlernt auf was bei einem Roll-Out geachtet werden muss. Sei es in der Vorbereitung, bei den Master Daten oder bei der Projektleitung und Kommunikation mit den Projektmitgliedern. '
      />
    ),
    erlebnisse: (
      <FormattedMessage
        id='bericht3.erlebnisse'
        defaultMessage='Das beste Erlebnis für mich war es Sydney und die Umgebung zu erkunden. Die Kängurus und Koalas sind sehr süß und sie das erste Mal in ihrer natürlichen Umgebung zu sehen war sehr besonders. Ein weiteres Highlight war der frisch renovierte Konzertsaal der Sydney Oper. '
      />
    ),
    tipps: (
      <FormattedMessage
        id='bericht3.tipps'
        defaultMessage='Regenjacke ist ein Muss. Auch interessant ist der gut aus- und aufgebaute öffentliche Nahverkehr. Da es eine Küstenstadt ist sollte man immer etwas zu drüber anziehen dabei haben, ein kleiner Wind weht immer.'
      />
    ),
  },
  {
    img: SingaporeImage, // URL
    city: 'Singapur',
    company: 'EY',
    abteilung: 'Technology Consulting - GRC',
    name: 'Marius Saborosch',
    standort: (
      <FormattedMessage
        id='bericht4.standort'
        defaultMessage='Für mich war es wichtig den praktischen Auslandsaufenthalt außerhalb von Europa zu machen, da wir bei EY auch unabhängig vom dualen Studium die Möglichkeit haben im EU-Ausland zu arbeiten. Außerdem war und bin ich generell von Asien fasziniert, war vorher allerdings noch nie dort und wollte in ein Land oder eine Stadt, die für Unternehmertum steht und mir auch die Möglichkeit bietet wichtige Erfahrungen für meine weitere Karriere zu sammeln. Als „Asien Light“ and „Manhattan Asiens“ hat sich Singapur demnach förmlich aufgedrängt. Und im Nachhinein war es definitiv eine gute Entscheidung. '
      />
    ),
    gelernt: (
      <FormattedMessage
        id='bericht4.gelernt'
        defaultMessage='In der Beratung zu Governance, Risk and Compliance Systemen habe ich eine neue Perspektive auf Risiko und Kundenarbeit um dieses Thema erhalten, da ich in Deutschland eher aus der PrüferPerspektive arbeite und somit in Singapur die Seite wechseln konnte, um das ganze Thema aus Unternehmensperspektive zu sehen. Natürlich hat dies auch andere Anforderungen mit sich gebracht, die mich dann dazu gebracht haben mich wesentlich intensiver mit Datenanalyse und Systemmigrationen zu beschäftigen als zuvor.'
      />
    ),
    erlebnisse: (
      <FormattedMessage
        id='bericht4.erlebnisse'
        defaultMessage='Das Beste war sowohl beruflich als auch persönlich eine neu Perspektive zu erhalten. Es war faszinierend neue Leute, Themen und Kulturen kennenzulernen. Außerdem ist Singapurs Nationaltag im August, wodurch ich an den Feierlichkeiten teilnehmen konnte – eine einmalige Erfahrung.'
      />
    ),
    tipps: (
      <FormattedMessage
        id='bericht4.tipps'
        defaultMessage='Singapur ist sehr sicher und man kann ohne Bedenken nachts unterwegs sein, allerdings sollte man immer einen Regenschirm dabei haben und hitzeresistent sein, denn Singapurs tropisches Klima verspricht Platzregen und fast immer mindestens 30°C. Außerdem sollte man sein Budget sehr großzügig planen, da Singapur in keinster Weise günstig ist. Definitiv verschiedene Hawker Center und die Atlas Bar ausprobieren!'
      />
    ),
  },
  {
    img: SydneyOperaImage, // URL
    city: 'Sydney',
    company: 'Linde',
    abteilung: 'IT Applications',
    name: 'Stefan Kuchenbauer',
    standort: (
      <FormattedMessage
        id='bericht5.standort'
        defaultMessage='Nachdem ich im Jahr zuvor, bereits ein Theoriesemester in Dublin verbracht hatte, wollte ich dieses Mal an einen Standort außerhalb Europas reisen. Die Aufgaben, die mich in Australien erwarteten klangen für mich zudem interessanter als in anderen Auslandsstandorten, die für mich in Frage kamen.'
      />
    ),
    gelernt: (
      <FormattedMessage
        id='bericht5.gelernt'
        defaultMessage='Die Projekte waren sehr vielseitig. Das duale Konzept ist außerhalb Deutschlands nur wenig bekannt. Dadurch hielten mich die Kollegen für einen regulären Mitarbeiter und ich konnte viel Verantwortung für Projekte und auch Führungsaufgaben übernehmen. Mein Englisch ist ebenfalls deutlich sicherer geworden, weil ich während meines Aufenthalts ausschließlich Englisch geredet habe.'
      />
    ),
    erlebnisse: (
      <FormattedMessage
        id='bericht5.erlebnisse'
        defaultMessage='Einer meiner Highlights war, als ich während meiner Mittagspause in einem Nationalpark in der Nähe unseres Büros spazieren war und dort ein Känguru in fünf Meter Entfernung gesehen habe. Außerdem habe ich das erste Mal in meinem Leben einen Wal und Pinguine in freier Wildbahn gesehen. Der Harbour Bridge Climb, bei dem man auf dem Bogen der Harbour Bridge läuft, war ebenfalls einmalig.'
      />
    ),
    tipps: (
      <FormattedMessage
        id='bericht5.tipps'
        defaultMessage='In Sydney ist man sehr stark auf öffentlichen Nahverkehr angewiesen, welcher zum Glück sehr gut ausgebaut ist. Fahrrad fahren ist keine angenehme Option, da die Stadt extrem hügelig ist. Achtet bei der Suche eures Apartments auf die Nähe zu dem Büro und dem Central Business District. Wohnungen findet man am besten auf Gumtree und Flatmates.'
      />
    ),
  },
  {
    img: ValenciaImage, // URL
    city: 'Valencia',
    company: 'Atos',
    abteilung: 'Microsoft 365 Migration',
    name: 'Leona Schäfer',
    standort: (
      <FormattedMessage
        id='bericht6.standort'
        defaultMessage='Als es für mich hieß, dass ich ins Ausland darf, war für mich klar, dass ich nach Spanien möchte. Valencia war der perfekte Standort, denn es liegt direkt am Meer und ist eine tolle Stadt.'
      />
    ),
    gelernt: (
      <FormattedMessage
        id='bericht6.gelernt'
        defaultMessage='Ich konnte meine Sprachkenntnisse verbessern. Außerdem habe ich durch die Zusammenarbeit mit dem spanischen Team noch mal einen anderen Blick in interkulturelle Kompetenzen bekommen.'
      />
    ),
    erlebnisse: (
      <FormattedMessage
        id='bericht6.erlebnisse'
        defaultMessage='Die besten Erlebnisse waren definitiv die Wochenenden, wo ich die Stadt von Valencia und die Natur außen rum kennenlernen durfte.'
      />
    ),
    tipps: (
      <FormattedMessage
        id='bericht6.tipps'
        defaultMessage='Man kann sich super durchschlemmen und viele Köstlichkeiten probieren. Die Paella kommt ursprünglich aus Valencia und schmeckt in Valencia am besten. Ebenfalls ein Muss ist das Aqua de Valencia.'
      />
    ),
  },
  {
    img: MelbourneImage, // URL
    city: 'Melbourne',
    company: 'Worldline',
    abteilung: 'Data Analytics',
    name: 'Lukas Linß',
    standort: (
      <FormattedMessage
        id='bericht7.standort'
        defaultMessage='Da man nicht in jeder Praxisphase die Chance hat, ins Ausland zu reisen und in einem internationalen Umfeld zu arbeiten, wollte ich so weit wie möglich in die Ferne, um diese einmalige Change so gut wie möglich auszunutzen. Ich würde sagen, mit dem Aufenthalt in Melbourne ist mir dies recht gut gelungen. Viele Freunde hatten mir bereits von Melbourne berichtet und ich muss sagen, dass mich die Stadt nicht enttäuscht hat. Ich habe selten Städte erlebt, die so international sind. Die Nähe zum Strand vermisse ich immer noch und die australische Natur ist atemberaubend. Zurecht zählt Melbourne zu den lebenswertesten Städten dieser Erde.'
      />
    ),
    gelernt: (
      <FormattedMessage
        id='bericht7.gelernt'
        defaultMessage='Als Mitglied des Data Analytics Teams habe ich während meines Aufenthalts eine Menge über die Arbeit mit Daten gelernt. Hierzu zählten vor allem die Arbeit mit relationalen Datenbanken, die ich zuvor vor allem aus dem Studium kannte, aber auch andere Technologien wie Python und KNIME, zur automatisierten Datenverarbeitung, die ich erst während der Arbeit erlernen musste.'
      />
    ),
    erlebnisse: (
      <FormattedMessage
        id='bericht7.erlebnisse'
        defaultMessage='Ich habe während meines Aufenthalts vieles erlebt, aber die beste Erfahrung muss bei Weitem mein Roadtrip über die Great Ocean Road gewesen sein. Die Great Ocean Road ist circa eine Stunde von Melbourne entfernt und verläuft 240 Kilometern entlang der Küste. Die Sicht und die Strände sind wie aus einem Bilderbuch und in der Natur sind verschiedenste australische Tiere zu finden.'
      />
    ),
    tipps: (
      <FormattedMessage
        id='bericht7.tipps'
        defaultMessage="Wer etwas länger in Melbourne ist, sollte auf jeden Fall ein Australian Football spiel im berühmten Melbourne Cricket Ground besuchen. Australier sind Absolute verrückt nach 'Footy' und die Stimmung ist überragend."
      />
    ),
  },
];
