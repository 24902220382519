/* eslint-disable react/no-unknown-property */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * ChatbotScript component that handles the inclusion and visibility of the chatbot
 * based on the current route.
 *
 * This component hides the chatbot on the '/tinbit' route and ensures that the
 * chatbot script is included on other routes. So the Chatbot Appears.
 *
 * @returns {null} This component does not directly render any UI elements.
 */
const ChatbotScript = () => {
  const location = useLocation();
  const normalizedPathname = location.pathname.toLowerCase();

  useEffect(() => {
    const chatButton = document.getElementById('chatbase-bubble-button');
    const messageBubbles = document.getElementById('chatbase-message-bubbles');

    // Hide or show the chat button and message bubbles based on the current route
    if (normalizedPathname === '/tinbit') {
      if (chatButton) {
        chatButton.style.display = 'none';
      }
      if (messageBubbles) {
        messageBubbles.style.display = 'none';
      }
    } else {
      if (chatButton) {
        chatButton.style.display = '';
      }
      if (messageBubbles) {
        messageBubbles.style.display = '';
      }
    }
  }, [normalizedPathname]);

  useEffect(() => {
    // Load the chatbot script only if not already loaded and not on the '/tinbit' route
    if (normalizedPathname !== '/tinbit' && !window.embeddedChatbotConfig) {
      window.embeddedChatbotConfig = {
        chatbotId: 'BWJwFwodNrfHCclHOq0PS',
        domain: 'www.chatbase.co',
      };

      const script = document.createElement('script');
      script.src = 'https://www.chatbase.co/embed.min.js';
      script.setAttribute('chatbotId', 'BWJwFwodNrfHCclHOq0PS');
      script.setAttribute('domain', 'www.chatbase.co');
      script.defer = true;
      script.crossOrigin = 'anonymous';

      document.body.appendChild(script);
    }
  }, [normalizedPathname]);

  return null;
};

export default ChatbotScript;
