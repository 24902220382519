import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import ButtonControls from '../components/tinbit/ButtonControls.js';
import seoImage from '../components/tinbit/media/tinbit-seo.png';
import '../components/tinbit/style/index.css';
import SwipeContainer from '../components/tinbit/SwipeContainer.js';
import TinbitStart from '../components/tinbit/TinbitStart.js';
import TopMenu from '../components/tinbit/TopMenu.js';

const TinBit = () => {
  const [cardType, setCardType] = useState('companies'); // Current type of card being displayed
  // Current index for each card
  const [indices, setIndices] = useState({
    companies: 0,
    lecturers: 0,
    alumni: 0,
  });
  const [favorites, setFavorites] = useState([]); // An array holding all the right swiped companies
  const [swipedItems, setSwipedItems] = useState([]); // An array holding all the swiped cards
  const [visible, setVisible] = useState(true); // Boolean indicating the the visibility of the current deck of cards
  const [returningCard, setReturningCard] = useState(null); // State to track the returning card
  const [isToggled, setIsToggled] = useState(false); // Boolean indicating if card switch is toggled
  const [showStart, setShowStart] = useState(true); // Boolean indicating if start screen is shown
  const [isFavoritesOpen, setIsFavoritesOpen] = useState(false); // Boolean indicating if the favorites list is open

  const swipeRef = useRef(null); // Reference for swiping
  const favoritesRef = useRef(null); // Reference for favorites

  useEffect(() => {
    // Set body overflow to hidden when the component mounts
    document.body.style.overflow = 'hidden';
    document.getElementsByClassName('footer')[0].style.position = 'absolute';
    document.getElementsByClassName('footer')[0].style.zIndex = '-5';
    document.getElementsByClassName('footer__text')[0].style.display = 'none';

    // Cleanup styles when the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
      document.getElementsByClassName('footer')[0].style.position = 'relative';
      document.getElementsByClassName('footer')[0].style.zIndex = '0';
      document.getElementsByClassName('footer__text')[0].style.display =
        'block';
    };
  }, []);

  // Function to handle a card swipe
  const handleSwipe = (type, item, direction) => {
    if (isToggled) setIsToggled(false); // On Swipe toggle Card Switch if it is open

    // Updates the count of swiped items for the current card deck
    setIndices((prevIndices) => ({
      ...prevIndices,
      [type]: prevIndices[type] + 1,
    }));

    // When card is swiped right and it is a company, card is saved in favorites
    if (direction === 'right') {
      if (
        type === 'companies' &&
        item &&
        item.image &&
        !favorites.some((fav) => fav.id === item.id)
      ) {
        setFavorites([...favorites, item]);
      }
    }

    // Add the swiped card to the swipedItems array
    setSwipedItems([...swipedItems, { item, direction }]);
  };

  // Handle a swipe to the left
  const handleSwipeLeft = () => {
    if (isFavoritesOpen) return;
    if (swipeRef.current) {
      swipeRef.current.swipeLeft();
    }
  };

  // Handle a swipe to the right
  const handleSwipeRight = () => {
    if (isFavoritesOpen) return;
    if (swipeRef.current) {
      swipeRef.current.swipeRight();
    }
  };

  // Restarts TinBit when clicking the restart button in the favorites container by resetting all swipes
  const restartTinbit = () => {
    setFavorites([]);
    setSwipedItems([]);
    setCardType('companies');
    setIndices({
      companies: 0,
      lecturers: 0,
      alumni: 0,
    });
    if (favoritesRef.current) {
      favoritesRef.current.toggleFavorites();
    }
  };

  // Reverts the last swipe when clicking the undo button
  const handleUndo = () => {
    if (isToggled) setIsToggled(false);
    if (isFavoritesOpen) return;
    if (swipedItems.length > 0) {
      const lastSwiped = swipedItems.pop();
      setSwipedItems([...swipedItems]); // Update the state to re-render

      setIndices((prevIndices) => ({
        ...prevIndices,
        [cardType]: Math.max(0, prevIndices[cardType] - 1), // Ensure the counter does not go negative
      }));

      // When the last swipe was right remove the cards from favorites
      if (lastSwiped.direction === 'right' && cardType === 'companies') {
        setFavorites(favorites.filter((fav) => fav.id !== lastSwiped.item.id));
      }

      // Set the returning card for animation
      setReturningCard(lastSwiped.item);
      setTimeout(() => {
        setReturningCard(null); // Clear the returning card after the animation
      }, 500);
    }
  };

  // Toggle the CardSwitch
  const toggleCardSwitch = () => {
    setIsToggled(!isToggled); // Toggle the state
    if (isFavoritesOpen) setIsFavoritesOpen(false); // Close favorites if card switch is toggled
  };

  // Change the currently displayed card deck
  const changeCardType = (newType) => {
    if (cardType === newType) {
      // If the new card type is the same as the current one, do nothing
      return;
    }
    // Change the card type and trigger the fade-in animation
    toggleCardSwitch();
    setVisible(false);
    setTimeout(() => {
      setCardType(newType);
      setVisible(true);
    }, 400); // Duration of the fade out transition
  };

  // Toggle the start screen
  const toggleStart = () => {
    setShowStart(!showStart);
  };

  // Toggle the favorites
  const toggleFavorites = () => {
    setIsFavoritesOpen(!isFavoritesOpen); // Toggle the favorites
    if (isToggled) setIsToggled(false); // Close card switch if favorites is toggled
  };

  return (
    <div className='tinbitMain'>
      {/* Helmet is used to manage the document head for SEO and metadata */}
      <Helmet>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <title>Tinbit - Swipe deinen Match</title>
        <meta
          name='description'
          content='Tinbit ist eine Tinder-ähnliche Anwendung, bei der du Unternehmen, Dozenten und Alumni swipen kannst.'
        />
        <meta
          name='keywords'
          content='Tinbit, swipen, Unternehmen, Dozenten, Alumni, Match'
        />
        <meta property='og:title' content='Tinbit - Swipe dein Match' />
        <meta
          property='og:description'
          content='Swipe Unternehmen, Dozenten und Alumni, um dein perfektes Match zu finden.'
        />
        <meta property='og:image' content={seoImage} />
        <meta property='og:url' content='imbit-n3xt.com/TinBit' />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' content='Tinbit - Swipe dein Match' />
        <meta
          name='twitter:description'
          content='Swipe Unternehmen, Dozenten und Alumni, um dein perfektes Match zu finden.'
        />
        <meta name='twitter:image' content={seoImage} />
      </Helmet>
      {/* AnimatePresence handles the mounting and unmounting of the TinbitStart component */}
      <AnimatePresence initial={false}>
        {showStart && <TinbitStart key='tinbit-start' onStart={toggleStart} />}
      </AnimatePresence>
      <div className='tinbitContainer'>
        {/* TopMenu component contains the controls for the app */}
        <TopMenu
          changeCardType={changeCardType} // Function to change the type of card being displayed
          toggleCardSwitchParent={toggleCardSwitch} // Function to toggle the card switcher
          isToggled={isToggled} // Boolean state indicating if the card switcher is toggled
          favorites={favorites} // Array of favorite items
          restartTinbit={restartTinbit} // Function to restart the app
          ref={favoritesRef} // Reference to the favorites component
          onHelpClick={toggleStart} // Function to toggle the start screen
          toggleFavorites={toggleFavorites} // Function to toggle the favorites list
          isFavoritesOpen={isFavoritesOpen} // Boolean state indicating if the favorites list is open
        />
        {/* AnimatePresence handles the transition animations of the card types */}
        <AnimatePresence mode='wait'>
          {visible && (
            <motion.div
              key={cardType}
              initial={{ opacity: 0, y: -20 }} // Initial state for animation
              animate={{ opacity: 1, y: 0 }} // Animate to state
              exit={{ opacity: 0, y: 20 }} // Exit state for animation
              transition={{ duration: 0.5, ease: 'easeOut' }} // Animation duration and easing
            >
              {/* SwipeContainer manages the swiping functionality */}
              <SwipeContainer
                showStart={toggleStart} // Function to toggle the start screen
                cardType={cardType} // Current type of card being displayed
                currentIndex={indices[cardType]} // Current index for the card type
                onSwipe={(item, direction) =>
                  handleSwipe(cardType, item, direction)
                } // Function to handle swipe actions
                swipeRef={swipeRef} // Reference to the swipe container
                returningCard={returningCard} // Card that is being returned after an undo action
                isFavoritesOpen={isFavoritesOpen} // Boolean state indicating if the favorites list is open
                isToggled={isToggled} // Boolean state indicating if the card switcher is toggled
                toggleFavorites={toggleFavorites} // Function to toggle the favorites list
                toggleCardSwitch={toggleCardSwitch} // Function to toggle the card switcher
              />
              {/* ButtonControls component contains the swipe and undo buttons */}
              <ButtonControls
                onSwipeLeft={handleSwipeLeft} // Function to trigger a left swipe
                onUndo={handleUndo} // Function to undo the last swipe
                onSwipeRight={handleSwipeRight} // Function to trigger a right swipe
                cardType={cardType} // Current type of card being displayed
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default TinBit;
