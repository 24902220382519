/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import courseIcon from '../general/media/icons/courses.svg';
import './style/lecturerAlumni.css';

/**
 * LecturerFront Component
 *
 * @param {Object} props - Component properties
 * @param {Object} props.item - The data item to be displayed on the front side of the card
 * @param {Function} props.onMoreInfo - Function to handle more info button click
 * @param {number} props.index - The index of the card in the list
 * @param {number} props.arrayLength - The total number of cards in the array
 * @param {number} props.position - The position of the card in the array
 *
 * @returns {JSX.Element} LecturerFront component
 */
const LecturerFront = ({ item, onMoreInfo, index, arrayLength, position }) => (
  <div className='card__side card__side--front'>
    {item && (
      <>
        <div className='card__upperBox'>
          {/* Display the current position and total number of items */}
          <p className='swipingStatus'>
            {position}{' '}
            <FormattedMessage id='tinbit.counter' defaultMessage='von' />{' '}
            {arrayLength}
          </p>
          <div className='card__personTop'>
            <div className='card__personTop--box' />
            {/* Display the lecturer's image */}
            <img
              className='card__personTop--image'
              src={item.image}
              alt={item.name}
              loading='lazy'
            />
          </div>
        </div>
        <div className='card__details'>
          {/* Display the lecturer's name */}
          <h2 className='card__person--name'>{item.name}</h2>
          <hr className='card__divider card__divider-person' />
          <div className='card__facts'>
            <div className='card__facts-item card__facts-item--courses'>
              <span className='card__facts-text'>
                <img src={courseIcon} alt='' className='card__facts-icon' />{' '}
                <FormattedMessage
                  id='tinbit.courses'
                  defaultMessage='Vorlesungen'
                />
              </span>
              {/* Display the courses */}
              <h3 className='card__facts-value'>{item.courses}</h3>
            </div>
          </div>
          {/* Button to view more info (CV) */}
          <button
            className='card__button card__button--more-info'
            onClick={index === 0 ? onMoreInfo : null}
            style={{ pointerEvents: index === 0 ? 'auto' : 'none' }}
            type='button'
          >
            <FormattedMessage
              id='tinbit.toCV'
              defaultMessage='Zum Lebenslauf'
            />
          </button>
        </div>
      </>
    )}
  </div>
);

export default LecturerFront;
