import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
import picture from './media/face1.png';
import './styles/AbiFertig.css';

/**
 * AbiFertig component that renders information about dual study opportunities
 * after completing high school or vocational training.
 *
 * @returns {JSX.Element} The rendered AbiFertig component.
 */
const AbiFertig = () => {
  return (
    <div className='abi-fertig padding-container padding-top'>
      <Helmet>
        <meta
          name='description'
          content='Erfahren Sie mehr über die Möglichkeiten eines dualen Studiums nach der Hochschulreife oder Berufsausbildung.'
        />
        <meta
          name='keywords'
          content='duales Studium, Hochschulreife, Berufsausbildung, Studium, Karriere'
        />
        <meta
          property='og:title'
          content='Abi fertig, was jetzt? - Duales Studium'
        />
        <meta
          property='og:description'
          content='Erfahren Sie mehr über die Möglichkeiten eines dualen Studiums nach der Hochschulreife oder Berufsausbildung.'
        />
        <meta
          property='og:url'
          content='https://www.mannheim.dhbw.de/dual-studieren/bachelor/wirtschaft/wirtschaftsinformatik/imbit'
        />
        <meta
          name='twitter:title'
          content='Abi fertig, was jetzt? - Duales Studium'
        />
        <meta
          name='twitter:description'
          content='Erfahren Sie mehr über die Möglichkeiten eines dualen Studiums nach der Hochschulreife oder Berufsausbildung.'
        />
        <meta name='twitter:image' content={picture} />
        <meta name='twitter:card' content='summary_large_image' />
      </Helmet>
      <img className='abi-fertig__picture' src={picture} alt='Face' />
      <div className='abi-fertig__text'>
        <h2 className='abi-fertig__title'>
          <FormattedMessage
            id='abiFertig.title'
            defaultMessage='Abi fertig, was jetzt?'
          />
        </h2>
        <p className='abi-fertig__paragraph'>
          <FormattedMessage
            id='abiFertig.paragraph'
            defaultMessage='Du hast die Hochschulreife erlangt oder bist kurz davor oder hast deine Berufsausbildung abgeschlossen und weißt noch nicht genau wie es weitergeht?{break}Ein interessantes und abwechslungsreiches duales Studium bietet dir die Möglichkeit deinen persönlichen und beruflichen Horizont zu erweitern.'
            values={{ break: <br /> }}
          />
        </p>
        {/* Uncomment the Button component when ready */}
        {/* <Button
					link="https://www.mannheim.dhbw.de/dual-studieren/bachelor/wirtschaft/wirtschaftsinformatik/imbit"
					defaultMessage="ÜBER UNS"
					target="_blank"
					id="abiFertig.button"
					className="abi-fertig__button"
				/> */}
      </div>
    </div>
  );
};

export default AbiFertig;
