/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import redwave from './media/shapes/endvideo-red-wave.svg';
import iamImbit from './media/videos/I-am-IMBIT.mp4';
import './styles/EndVideo.css';

/**
 * EndVideo component that renders a video and additional informational text.
 * It changes the footer's background color based on the current route.
 *
 * @returns {JSX.Element} The rendered EndVideo component.
 */
const EndVideo = () => {
  const location = useLocation();
  const originalBackgroundColor = useRef(null);

  useEffect(() => {
    const footerElement = document.querySelector('.footer');

    if (footerElement) {
      if (originalBackgroundColor.current === null) {
        originalBackgroundColor.current = footerElement.style.backgroundColor;
      }

      if (location.pathname === '/') {
        footerElement.style.backgroundColor = 'var(--color-primary)';
      } else {
        footerElement.style.backgroundColor = originalBackgroundColor.current;
      }
    }

    return () => {
      if (footerElement && originalBackgroundColor.current !== null) {
        footerElement.style.backgroundColor = originalBackgroundColor.current;
      }
    };
  }, [location.pathname]);

  return (
    <div className='end-video padding-top'>
      <img src={redwave} alt='red wave' className='end-video__wave' />
      <div className='padding-container end-video__container'>
        <video
          className='end-video__video'
          loop
          controls
          loading='lazy'
          aria-label='I am IMBIT video'
        >
          <source src={iamImbit} type='video/mp4' />
          Your Browser does not support the video tag.
        </video>
        <div className='end-video__text'>
          <h2 className='end-video__heading'>
            <FormattedMessage
              id='endvideo.heading'
              defaultMessage='Du brauchst mehr Infos?'
            />
          </h2>
          <p>
            <FormattedMessage
              id='endvideo.paragraphFirst'
              defaultMessage='Besuche doch die '
            />
            <a
              href='https://www.mannheim.dhbw.de/studium/bachelor/wirtschaft/wirtschaftsinformatik/imbit'
              target='_blank'
              rel='noreferrer'
              aria-label='Website der DHBW Mannheim'
            >
              <FormattedMessage
                id='endvideo.paragraphFirstLink'
                defaultMessage='Website der DHBW Mannheim'
              />
            </a>
            <FormattedMessage
              id='endvideo.paragraphSecond'
              defaultMessage=', um mehr über unseren Studiengang zu erfahren oder '
            />
            <a
              href='https://www.mannheim.dhbw.de/studium/bachelor/wirtschaft/wirtschaftsinformatik/imbit/ansprechpersonen'
              target='_blank'
              rel='noreferrer'
              aria-label='Kontaktiere unsere Ansprechpersonen'
            >
              <FormattedMessage
                id='endvideo.paragraphSecondLink'
                defaultMessage='kontaktiere unsere Ansprechpersonen!'
              />
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default EndVideo;
