import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
import stefan from './media/stefan.jpg';
import picture3 from './media/theorieausland1.png';
import './styles/TheorieAusland.css';

/**
 * TheorieAusland component representing the "Theorie im Ausland" section of the website.
 * @returns {JSX.Element} The rendered TheorieAusland component.
 */
const TheorieAusland = () => {
  return (
    <div className='TheorieAusland padding-container padding-top'>
      <Helmet>
        <meta
          name='description'
          content='Erfahren Sie mehr über Auslandserfahrungen und die wertvollen Einblicke, die durch Auslandssemester gewonnen werden. Lesen Sie Stefans Erfahrungsbericht aus Dublin.'
        />
        <meta
          name='keywords'
          content='Auslandssemester, Theorie im Ausland, Dublin, Erfahrungsberichte, Auslandserfahrungen, Englisch verbessern, internationale Freundschaften'
        />
        <meta
          property='og:title'
          content='Theorie im Ausland | Erfahrungsberichte und Eindrücke'
        />
        <meta
          property='og:description'
          content='Erfahren Sie mehr über Auslandserfahrungen und die wertvollen Einblicke, die durch Auslandssemester gewonnen werden. Lesen Sie Stefans Erfahrungsbericht aus Dublin.'
        />
        <meta property='og:image' content={picture3} />
        <meta property='og:type' content='article' />
      </Helmet>
      <h2 className='TheorieAusland__title'>
        <FormattedMessage
          id='theorieAusland.title'
          defaultMessage='Theorie im Ausland'
        />
      </h2>
      <div className='TheorieAusland__testimonial-wrapper'>
        <div className='TheorieAusland__image-wrapper'>
          <img
            className='TheorieAusland__image-pub'
            src={picture3}
            alt='Pub (the temple bar) in Dublin decorated with flowers'
            loading='lazy'
          />
        </div>
        <div className='TheorieAusland__text'>
          <div className='TheorieAusland__text-header'>
            <img
              className='TheorieAusland__image-person'
              src={stefan}
              alt='Portrait of Person'
              loading='lazy'
            />
            <div>
              <h3 className='TheorieAusland__text-header-name'>
                <FormattedMessage
                  id='TheorieAusland__text-header-name'
                  defaultMessage='Stefan Kuchenbauer'
                />
              </h3>
              <p className='TheorieAusland__text-header-ort'>
                <FormattedMessage
                  id='TheorieAusland__text-header-ort'
                  defaultMessage='Auslandssemester in Dublin'
                />
              </p>
            </div>
          </div>
          <p className='TheorieAusland__text-content'>
            <FormattedMessage
              id='theorieAusland.testimonial'
              defaultMessage='Meine Auslandssemester in Irland war eine einmalige Erfahrung! Dublin hat einen wunderbaren Flair und ich habe Freunde aus der ganzen Welt kennengelernt. Die Auslandserfahrung war auch sehr wertvoll, um mein Englisch zu verbessern. Ich freue mich schon sehr auf weitere Auslandserfahrungen in meinem weiteren Berufsleben.'
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default TheorieAusland;
