/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import './style/companies.css';

/**
 * CompaniesBack Component
 *
 * @param {Object} props - Component properties
 * @param {Object} props.item - The data item to be displayed on the back side of the card
 * @param {Function} props.onBack - Function to handle back button click
 * @param {number} props.index - The index of the card in the list
 *
 * @returns {JSX.Element} CompaniesBack component
 */
const CompaniesBack = ({ item, onBack, index }) => (
  <div className='card__side'>
    {item && (
      <>
        <div className='card__details--back'>
          <div className='card__company--back'>
            {/* Display the company's logo */}
            <img
              src={item.logo}
              alt={item.name}
              className='card__logo'
              loading='lazy'
            />
            {/* Display the company's name */}
            <h2 className='card__company-title'>{item.name}</h2>
          </div>
          <hr className='card__divider card__divider-back' />
          <div className='card__company-description'>
            {/* Display the company's description */}
            <p className='description--text'>{item.description}</p>
          </div>
          <div className='card__apply'>
            <p className='card__apply-text'>
              <FormattedMessage
                id='tinbit.apply'
                defaultMessage='Jetzt bewerben:'
              />
            </p>
            {/* Link to the company's application page */}
            <a
              className='card__apply-link'
              target='_blank'
              href={item.applyLink}
              rel='noreferrer'
            >
              {item.applyLinkShort}
            </a>
          </div>
        </div>
        {/* Button to go back to the front side */}
        <button
          className='card__button card__button--go-back'
          onClick={index === 0 ? onBack : null}
          style={{ pointerEvents: index === 0 ? 'auto' : 'none' }}
          type='button'
        >
          <FormattedMessage id='tinbit.backToFront' defaultMessage='Zurück' />
        </button>
      </>
    )}
  </div>
);

export default CompaniesBack;
