import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

/**
 * FaviconSwitcher component that switches the favicon based on the user's color scheme preference.
 * It updates the favicon to a light or dark version depending on whether dark mode is enabled.
 *
 * @returns {JSX.Element} The rendered Helmet component that sets the favicon.
 */
const FaviconSwitcher = () => {
  useEffect(() => {
    /**
     * Sets the favicon based on the color scheme preference.
     * @param {boolean} isDarkMode - Indicates if dark mode is enabled.
     */
    const setFavicon = (isDarkMode) => {
      const favicon = document.querySelector('link[rel="icon"]');
      const href = isDarkMode ? '/favicon-light.ico' : '/favicon-dark.ico';
      if (favicon) {
        favicon.href = href;
      }
    };

    // Initial check for dark mode preference
    const isDarkMode =
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches;
    setFavicon(isDarkMode);

    // Listen for changes in color scheme preference
    const darkModeMediaQuery = window.matchMedia(
      '(prefers-color-scheme: dark)',
    );
    const handleChange = (e) => setFavicon(e.matches);
    darkModeMediaQuery.addEventListener('change', handleChange);

    // Cleanup event listener on component unmount
    return () => {
      darkModeMediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  // Render a single favicon link with a default href based on the initial color scheme preference
  return (
    <Helmet>
      <link
        rel='icon'
        type='image/x-icon'
        href={
          window.matchMedia &&
          window.matchMedia('(prefers-color-scheme: dark)').matches
            ? '/favicon-light.ico'
            : '/favicon-dark.ico'
        }
      />
    </Helmet>
  );
};

export default FaviconSwitcher;
