/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { LanguageSelector } from './LanguageSelector.js';
import newTabIcon from './media/icons/newTab.svg';
import logo from './media/imbitNextLogo.svg';
import './styles/Navbar.css';

/**
 * Navbar component for the application.
 * Manages navigation state and handles scroll and resize events.
 *
 * @returns {JSX.Element} The rendered Navbar component.
 */
const Navbar = () => {
  // State to manage whether the navbar should stick to the top
  const [isNavStick, setNavStick] = useState(false);

  // State to manage whether the menu is open
  const [menuOpen, setMenuOpen] = useState(false);

  // State to manage the window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Ref to access the navbar DOM element
  const navbarRef = useRef(null);

  /**
   * Handles scroll event to toggle navbar stickiness.
   */
  const handleScroll = () => {
    if (window.scrollY > 50) {
      setNavStick(true);
    } else {
      setNavStick(false);
    }
  };

  // Add scroll event listener on mount and clean up on unmount
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  /**
   * Handles resize event to manage body overflow based on window width.
   */
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
    if (window.innerWidth > 768) {
      document.body.style.overflow = 'auto';
    } else {
      document.body.style.overflow = menuOpen ? 'hidden' : 'auto';
    }
  };

  // Add resize event listener on mount and clean up on unmount
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      document.body.style.overflow = 'auto';
    };
  }, [menuOpen]);

  /**
   * Toggles the menu open/closed state.
   */
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  /**
   * Handles clicks on the overlay to close the menu.
   */
  const handleOverlayClick = () => {
    toggleMenu();
  };

  return (
    <>
      <div
        ref={navbarRef}
        className={`nav-sticky ${isNavStick ? 'affix' : ''}`}
      >
        <nav className={`nav ${menuOpen ? 'menu-open' : ''}`}>
          <div className='nav-container'>
            <div className='logo'>
              <Link to='/'>
                <img className='nav-logo' src={logo} alt='IMBIT-Logo' />
              </Link>
            </div>
            <div
              id='mainNavDiv'
              className={`mainNavDiv ${menuOpen ? 'show_list' : ''}`}
            >
              <ul className='navlinks'>
                <NavItem
                  to='/'
                  label='IMBIT'
                  id='navbar.imbit'
                  onItemClick={toggleMenu}
                />
                <NavItem
                  to='/Ausland'
                  label='Ausland'
                  id='navbar.ausland'
                  onItemClick={toggleMenu}
                />
                <NavItem
                  to='/TinBit'
                  label='TinBit'
                  id='navbar.tinbit'
                  onItemClick={toggleMenu}
                />
                <NavItem
                  href='https://www.spatial.io/s/IMBIT-Expo-636a219beea9af00012a5956?share=324222080615529969'
                  label='Metaverse'
                  external
                  id='navbar.metaverse'
                  onItemClick={toggleMenu}
                  icon={newTabIcon}
                />
                <LanguageSelector />
              </ul>
            </div>
            <button
              type='button'
              className={`burgerMenu ${menuOpen ? 'active' : ''}`}
              onClick={toggleMenu}
              aria-label='Toggle Menu'
            >
              <i />
              <i />
              <i />
            </button>
          </div>
        </nav>
      </div>
      {menuOpen && windowWidth <= 768 && (
        <div className='overlay' onClick={handleOverlayClick} />
      )}
    </>
  );
};

const NavItem = ({ to, href, label, external, id, onItemClick, icon }) => {
  const handleClick = () => {
    if (onItemClick) {
      onItemClick();
    }
  };

  if (external) {
    return (
      <li onClick={handleClick}>
        <a
          href={href}
          target='_blank'
          rel='noopener noreferrer'
          className='link'
        >
          <FormattedMessage id={id} defaultMessage={label} />
        </a>
        {icon && <img className='navIcon' src={icon} alt='nav-icon' />}
      </li>
    );
  }
  return (
    <li onClick={handleClick}>
      <Link className='link' to={to}>
        <FormattedMessage id={id} defaultMessage={label} />
      </Link>
    </li>
  );
};

export default Navbar;
