/* eslint-disable jsx-a11y/control-has-associated-label */

import { motion } from 'framer-motion';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import iconCardSwitchOrange from '../general/media/icons/cardSwitchOrange.svg';
import iconFavoritesOrange from '../general/media/icons/favoritesOrange.svg';
import iconSwipeOrange from '../general/media/icons/swipeOrange.svg';
import './style/tinbitStart.css'; // Create this CSS file for the styling

/**
 * TinbitStart Component
 *
 * @param {Object} props - Component properties
 * @param {Function} props.onStart - Function triggered when the start button is clicked
 *
 * @returns {JSX.Element} TinbitStart component
 */
const TinbitStart = ({ onStart }) => {
  return (
    <motion.div
      className='tinbit-start'
      initial={{ opacity: 0, top: 20 }}
      animate={{ opacity: 1, top: 0 }}
      exit={{ opacity: 0, top: 20 }}
      transition={{ duration: 0.3, ease: 'easeOut' }}
    >
      <div className='tinbit-start__content'>
        <p className='tinbit-start__heading'>
          <FormattedMessage
            id='tinbit.subWelcome'
            defaultMessage='Finde dein Unternehmen'
          />
        </p>
        <h1 id='tinbit.welcome' className='tinbit-start__title'>
          <FormattedMessage
            id='tinbit.welcome'
            defaultMessage='Willkommen bei TinBit!'
          />
        </h1>
        <p className='tinbit-start__description'>
          <FormattedMessage
            id='tinbit.explanation'
            defaultMessage='Mit diesem Tool, das auf der Swipe-Funktion von Tinder basiert, kannst
          du unsere Partnerunternehmen, Dozenten und Alumni kennenlernen.'
          />
        </p>
        <ul className='tinbit-start__list'>
          <li className='tinbit-start__list-item'>
            <img
              className='tinbit-start__icon'
              src={iconSwipeOrange}
              alt='Swipe icon'
            />
            <FormattedMessage
              id='tinbit.explanationSwiping'
              defaultMessage='Swipe um zu liken'
            />
          </li>
          <li className='tinbit-start__list-item'>
            <img
              className='tinbit-start__icon'
              src={iconFavoritesOrange}
              alt='Favorite icon'
            />
            <FormattedMessage
              id='tinbit.explanationFavorites'
              defaultMessage='Schau dir deine Lieblingsunternehmen an'
            />
          </li>
          <li className='tinbit-start__list-item'>
            <img
              className='tinbit-start__icon'
              src={iconCardSwitchOrange}
              alt='Switch icon'
            />
            <FormattedMessage
              id='tinbit.explanationSwitch'
              defaultMessage='Wechsel zwischen Unternehmen, Dozenten und Alumni'
            />
          </li>
        </ul>
        <button
          className='tinbit-start__button'
          onClick={onStart}
          type='button'
        >
          <FormattedMessage
            id='tinbit.startButton'
            defaultMessage="Los geht's"
          />
        </button>
      </div>
    </motion.div>
  );
};

export default TinbitStart;
