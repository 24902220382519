import React from 'react';
import { FormattedMessage } from 'react-intl';
import arrowCircledIcon from '../general/media/icons/arrowCircled.svg';
import crossIcon from '../general/media/icons/close.svg';
import heartIcon from '../general/media/icons/heart.svg';
import undoIcon from '../general/media/icons/undo.svg';
import './style/controls.css'; // Import your CSS file

/**
 * ButtonControls Component
 *
 * @param {Object} props - Component properties
 * @param {Function} props.onSwipeLeft - Function to handle the left swipe action
 * @param {Function} props.onUndo - Function to handle the undo action
 * @param {Function} props.onSwipeRight - Function to handle the right swipe action
 * @param {string} props.cardType - The type of the current card deck
 *
 * @returns {JSX.Element} ButtonControls component
 */
const ButtonControls = ({ onSwipeLeft, onUndo, onSwipeRight, cardType }) => {
  const isCompanyType = cardType === 'companies';

  return (
    <div className='button-controls'>
      {/* Left button: "Nein" for companies, "Vorheriger" for others */}
      <button
        className={`button-controls__btn ${isCompanyType ? 'button-controls__btn--left' : 'button-controls__btn--leftDark'}`}
        onClick={isCompanyType ? onSwipeLeft : onUndo}
        type='button'
      >
        <img src={isCompanyType ? crossIcon : arrowCircledIcon} alt='nein' />
        {isCompanyType ? (
          <FormattedMessage id='tinbit.swipeLeft' defaultMessage='Nein' />
        ) : (
          <FormattedMessage id='tinbit.lastCard' defaultMessage='Vorheriger' />
        )}
      </button>
      {/* Undo button: only visible for companies */}
      {isCompanyType && (
        <button
          className='button-controls__btn button-controls__btn--undo'
          onClick={onUndo}
          type='button'
        >
          <img src={undoIcon} alt='undo' />
        </button>
      )}
      {/* Right button: "Merken" for companies, "Nächster" for others */}
      <button
        className={`button-controls__btn ${isCompanyType ? 'button-controls__btn--right' : 'button-controls__btn--rightDark'}`}
        onClick={onSwipeRight}
        type='button'
      >
        <img src={isCompanyType ? heartIcon : arrowCircledIcon} alt='merken' />
        {isCompanyType ? (
          <FormattedMessage id='tinbit.swipeRight' defaultMessage='Merken' />
        ) : (
          <FormattedMessage id='tinbit.nextCard' defaultMessage='Nächster' />
        )}
      </button>
    </div>
  );
};

export default ButtonControls;
