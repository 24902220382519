// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import externalLinkIcon from './media/icons/newTab.svg';
import './styles/Button.css';

/**
 * Button component that renders a styled button wrapped in an anchor tag.
 * The button can optionally include an icon indicating an external link.
 * @param {Object} props - The properties passed to the component
 * @param {string} props.link - The URL to navigate to when the button is clicked
 * @param {string} props.defaultMessage - The default message to display on the button
 * @param {string} [props.className] - Additional class names for styling
 * @param {string} [props.id] - The ID for the formatted message
 * @param {string} [props.target] - Specifies where to open the linked document (e.g., '_blank' for new tab)
 * @param {React.Ref} ref - Reference to the button element
 * @returns {JSX.Element} The rendered Button component
 */
const Button = React.forwardRef((props, ref) => {
  const { link, defaultMessage, className, id, target, onClick } = props;

  const defaultClass = 'button';
  const combinedClassName = `${defaultClass} ${className}`.trim();

  return (
    <a
      href={link}
      target={target}
      rel='noopener noreferrer'
      className='button__parent'
    >
      <button
        type='button'
        onClick={onClick}
        className={combinedClassName}
        ref={ref}
      >
        <FormattedMessage id={id} defaultMessage={defaultMessage} />
        {target === '_blank' && (
          <img
            className='button__icon'
            src={externalLinkIcon}
            alt='externer Link'
          />
        )}
      </button>
    </a>
  );
});

Button.defaultProps = {
  className: '',
  id: '',
  target: '_self', // Assuming _self as default target
};

Button.propTypes = {
  link: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  target: PropTypes.string,
};

export default Button;
