/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import './style/lecturerAlumni.css';

/**
 * LecturerBack Component
 *
 * @param {Object} props - Component properties
 * @param {Object} props.item - The data item to be displayed on the back side of the card
 * @param {Function} props.onBack - Function to handle back button click
 *
 * @returns {JSX.Element} LecturerBack component
 */
const LecturerBack = ({ item, onBack }) => (
  <div className='card__side'>
    {item && (
      <>
        <div className='card__personDetails--back'>
          <div className='card__personDetails--head'>
            <h3 className='card__personBack--title'>
              <FormattedMessage id='tinbit.CV' defaultMessage='Lebenslauf' />
            </h3>
            <h2 className='card__company-title'>{item.name}</h2>
          </div>
          <hr className='card__divider card__divider-back' />
          <div className='card__cv'>
            <ul className='card__cv-items'>
              {item.cv.map((job) => (
                <li className='card__cv-item' key={job.id}>
                  <span className='card__cv-year'>{job.year}</span>
                  <p>{job.position}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {/* Button to go back to the front side */}
        <button
          className='card__button card__button--go-back'
          onClick={onBack}
          type='button'
        >
          <FormattedMessage id='tinbit.backToFront' defaultMessage='Zurück' />
        </button>
      </>
    )}
  </div>
);

export default LecturerBack;
