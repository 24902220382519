/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useLanguage } from '../general/LanguageSelector.js';
import cardSwitchIcon from '../general/media/icons/cardSwitch.svg';
import heartIcon from '../general/media/icons/heart.svg';
import AlumniBack from './AlumniBack.js';
import AlumniFront from './AlumniFront.js';
import CompaniesBack from './CompaniesBack.js';
import CompaniesFront from './CompaniesFront.js';
import LecturerBack from './LecturerBack.js';
import LecturerFront from './LecturerFront.js';
import SwipeCard from './SwipeCard.js';

const SwipeContainer = ({
  cardType,
  currentIndex,
  onSwipe,
  swipeRef,
  returningCard,
  showStart,
  isFavoritesOpen,
  isToggled,
  toggleFavorites,
  toggleCardSwitch,
}) => {
  const { selectedLanguage } = useLanguage();
  const [data, setData] = useState([]);
  const [arrayLength, setArrayLength] = useState(0);

  const saveToLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  const getFromLocalStorage = (key) => {
    const value = localStorage.getItem(key);
    if (value) {
      try {
        return JSON.parse(value);
      } catch (error) {
        console.error('Error parsing JSON from localStorage', error);
        return null;
      }
    }
    return null;
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const dataModule = await import('./data/data.js');

        let newData = [];
        switch (cardType) {
          case 'lecturers':
            newData = dataModule.lecturers;
            break;
          case 'alumni':
            newData = dataModule.alumni;
            break;
          case 'companies':
            newData = dataModule.companies;
            break;
          default:
            newData = dataModule.companies;
            break;
        }

        setData(newData);
        setArrayLength(newData.length);

        // Save the data to local storage
        saveToLocalStorage('swipeData', newData);
      } catch (error) {
        console.error(
          `Failed to load data for language ${selectedLanguage}, falling back to default data`,
          error,
        );

        // Attempt to load from local storage if the fetch fails
        const cachedData = getFromLocalStorage('swipeData');
        if (cachedData) {
          setData(cachedData);
          setArrayLength(cachedData.length);
        }
      }
    };

    loadData();
  }, [cardType, selectedLanguage]);

  const visibleItems = data.slice(currentIndex, currentIndex + 3);
  const returningIndex = visibleItems.findIndex(
    (item) => item && returningCard && item.id === returningCard.id,
  );

  return (
    <div className='swipe-container'>
      {visibleItems.map((item, index) => (
        /**
         * SwipeCard Component
         *
         * @param {Object} props - Component properties
         * @param {boolean} props.showStart - Boolean indicating if the start screen should be shown
         * @param {boolean} props.isFavoritesOpen - Boolean indicating if the favorites section is open
         * @param {boolean} props.isToggled - Boolean indicating if the card switch is toggled
         * @param {React.Component} props.FrontComponent - Front side component of the swipe card
         * @param {React.Component} props.BackComponent - Back side component of the swipe card
         * @param {Object} props.item - The data item to be displayed on the card
         * @param {number} props.index - The index of the card in the list
         * @param {Function} props.onSwipe - Function called when a card is swiped
         * @param {boolean} props.showOverlay - Boolean indicating if the green/red overlay should be shown
         * @param {Object} props.ref - Reference to the swipeable card component
         * @param {boolean} props.isReturning - Boolean indicating if the card is returning to the deck
         * @param {number} props.arrayLength - The total number of cards in the array
         * @param {number} props.position - The position of the card in the array
         *
         * @returns {JSX.Element} SwipeCard component
         */
        <SwipeCard
          showStart={showStart}
          isFavoritesOpen={isFavoritesOpen}
          isToggled={isToggled}
          key={item.id}
          FrontComponent={
            cardType === 'lecturers'
              ? LecturerFront
              : cardType === 'alumni'
                ? AlumniFront
                : CompaniesFront
          }
          BackComponent={
            cardType === 'lecturers'
              ? LecturerBack
              : cardType === 'alumni'
                ? AlumniBack
                : CompaniesBack
          }
          item={item}
          index={index}
          onSwipe={onSwipe}
          showOverlay={cardType === 'companies'}
          ref={index === 0 ? swipeRef : null}
          isReturning={index === returningIndex}
          arrayLength={arrayLength}
          position={currentIndex + index + 1}
        />
      ))}
      <h3 className='allSwiped--heading'>Du hast alle Karten geswiped!</h3>
      <p className='allSwiped--text'>
        <span className='allSwiped--button' onClick={toggleCardSwitch}>
          <img src={cardSwitchIcon} alt='Switch' /> Wechsel
        </span>{' '}
        doch auf die anderen Kategorien oder schaue dir deine
        <span className='allSwiped--button' onClick={toggleFavorites}>
          <img src={heartIcon} alt='Favorites' />
          Favoriten
        </span>{' '}
        an!
      </p>
    </div>
  );
};

export default SwipeContainer;
