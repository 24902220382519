import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import wave from './media/shapes/multi-red-waves.svg';
import './styles/Footer.css';

/**
 * Footer component that renders the footer section of the website.
 *
 * The footer includes a link to the Impressum page and a copyright notice.
 * It also includes a decorative wave image.
 *
 * @returns {JSX.Element} The rendered Footer component.
 */
const Footer = () => {
  const impressum = useRef();

  /**
   * Handles the click event on the Impressum link.
   *
   * This function scrolls the window to the top smoothly after a brief timeout.
   */
  const clickHandler = () => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 1);
  };

  return (
    <footer className='footer padding-top'>
      <div className='footer__wave-container'>
        <img
          className='footer__wave'
          src={wave}
          alt='decorative Element'
          loading='lazy'
        />
        <div className='footer__content'>
          <div className='padding-container footer__text'>
            <Link
              to='/Impressum'
              className='footer__link__impressum'
              onClick={clickHandler}
            >
              <p ref={impressum}>Impressum</p>
            </Link>
            <p>Copyright &#169; 2024 IMBIT All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
