import React from 'react';
import { Helmet } from 'react-helmet-async';
import '../components/general/styles/Impressum.css';

/**
 * Impressum component displays the legal notice and data protection
 * information for the IMBIT program at DHBW Mannheim.
 *
 * @returns {JSX.Element} The rendered Impressum component.
 */
const Impressum = () => {
  return (
    <div className='impressum padding-top padding-container'>
      <Helmet>
        <title>
          Impressum und Datenschutzerklärung | Studiengang IMBIT, DHBW Mannheim
        </title>
      </Helmet>
      <h1 className='impressum__heading impressum__heading--primary'>
        Impressum und Datenschutzerklärung
      </h1>
      <p className='impressum__paragraph'>
        Angaben gemäß § 5 TMG und Kontakt für Auskunftsersuchen nach DSGVO:
        <br />
        Studiengang IMBIT, DHBW Mannheim www.mannheim.dhbw.de/wi-imbit
        <br />
        harald.bendl@dhbw-mannheim.de
        <br />
        Coblitzallee 1 – 9, D-68163 Mannheim
      </p>
      <h2 className='impressum__heading impressum__heading--secondary'>
        Haftungsausschluss
      </h2>
      <p className='impressum__paragraph'>
        Wir übernehmen keine Gewähr für die Aktualität, Richtigkeit und
        Vollständigkeit der bereitgestellten Informationen. Haftungsansprüche
        sind grundsätzlich nach § 8–10 TMG ausgeschlossen, sofern kein
        nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt.
        Alle Angebote sind freibleibend und unverbindlich. Wir behalten uns
        ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne
        gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die
        Veröffentlichung zeitweise oder endgültig einzustellen.
      </p>
      <h2 className='impressum__heading impressum__heading--secondary'>
        Informationen über erfasste Daten
      </h2>
      <p className='impressum__paragraph'>
        Einige Daten werden erhoben, wenn Sie sich zur Nutzung des Metaverses
        von dem Drittanbieter Spatial entscheiden. Es steht Ihnen frei, sich mit
        Pseudonym und einer nur zu diesem Zweck beschafften E-Mail-Adresse
        anzumelden. Falls Sie user Quiz „Wie dual bin ich“ bearbeiten, könnte
        ihr Spielefortschritt eingesehen und beeinflusst werden. Wie in den
        Angaben zum Copyright aufgeführt, verwendet unserer Webauftritt
        Ressourcen dritter Anbieter, was Datenerfassung durch diese Stellen zur
        Folge haben kann.
      </p>
      <h2 className='impressum__heading impressum__heading--secondary'>
        Urheberrecht
      </h2>
      <p className='impressum__paragraph'>
        Wir beachten in allen Publikationen die Urheberrechte und greifen
        bevorzugt auf selbst erstellte Grafiken, Tondokumente, Videosequenzen
        und Texte zurück. Das Copyright für die selbst erstellten Objekte bleibt
        bei den Autoren; für eine Verwendung dieser Objekte in anderen
        elektronischen oder gedruckten Publikationen holen Sie bitte unsere
        Zustimmung ein.
      </p>
      <h2 className='impressum__heading impressum__heading--secondary'>
        Anwendbares Recht
      </h2>
      <p className='impressum__paragraph'>
        Es gilt ausschließlich das maßgebliche Recht der Bundesrepublik
        Deutschland. Diese Nutzungshinweise sind als Teil des Internetangebotes
        zu betrachten, von dem aus auf diese Seite verwiesen wurde.
      </p>
      <h2 className='impressum__heading impressum__heading--secondary'>
        Angaben zum Copyright
      </h2>
      <p className='impressum__paragraph'>
        Verwendete Frameworks:{' '}
        <a className='link' href='https://de.reactjs.org/'>
          Reactjs
        </a>
        <br />
        <a className='link' href='https://pixabay.com/'>
          pixabay
        </a>
        ,{' '}
        <a className='link' href='https://unsplash.com/fr'>
          Unsplash
        </a>
      </p>
      <h2 className='impressum__heading impressum__heading--secondary'>
        Weitere IMBIT Seiten:
      </h2>
      <a
        className='link'
        href='https://docs.brillianideas.com/start'
        target='_blank'
        rel='external noreferrer'
      >
        docs.brillianideas.com
      </a>
    </div>
  );
};

export default Impressum;
