import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
import img4 from './media/chat-image4.png';
import hendrik from './media/hendrik.png';
import laura from './media/laura.png';
import path from './media/lion.mp3';
import meike from './media/meike.png';
import './styles/Testimonial.css';

/**
 * Testimonial component that displays success stories of IMBIT graduates.
 * Easteregg: Plays an audio clip on double-clicking the elite title.
 *
 * @returns {JSX.Element} The rendered Testimonial component.
 */
const Testimonial = () => {
  const testimonialRef = useRef();
  const audioRef = useRef(null);

  useEffect(() => {
    audioRef.current = new Audio(path);
    const handleDoubleClick = () => {
      audioRef.current.play();
    };
    const testimonialElement = testimonialRef.current;
    testimonialElement.addEventListener('dblclick', handleDoubleClick);

    return () => {
      testimonialElement.removeEventListener('dblclick', handleDoubleClick);
    };
  }, []);

  return (
    <div className='testimonial padding-container padding-top'>
      <Helmet>
        <meta
          name='description'
          content='Lesen Sie die Erfolgsgeschichten unserer IMBIT-Absolventen und erfahren Sie, wie das Studium an der DHBW Mannheim ihre Karrieren vorangebracht hat.'
        />
        <meta
          name='keywords'
          content='IMBIT, DHBW Mannheim, Testimonials, Erfolgsgeschichten, Absolventen, Karriere, Cyber Security Consultant, IT Inhouse Consultant, Business Consultant, Agile Specialist'
        />
        <meta name='robots' content='index, follow' />
        <link
          rel='canonical'
          href='https://www.mannheim.dhbw.de/dual-studieren/bachelor/wirtschaft/wirtschaftsinformatik/imbit/testimonials'
        />

        {/* Twitter Card */}
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:site' content='@dhbw_mannheim' />
        <meta
          name='twitter:title'
          content='Testimonials - DHBW Mannheim IMBIT'
        />
        <meta
          name='twitter:description'
          content='Lesen Sie die Erfolgsgeschichten unserer IMBIT-Absolventen und erfahren Sie, wie das Studium an der DHBW Mannheim ihre Karrieren vorangebracht hat.'
        />
        <meta name='twitter:image' content={img4} />

        {/* Google Card */}
        <meta itemProp='name' content='Testimonials - DHBW Mannheim IMBIT' />
        <meta
          itemProp='description'
          content='Lesen Sie die Erfolgsgeschichten unserer IMBIT-Absolventen und erfahren Sie, wie das Studium an der DHBW Mannheim ihre Karrieren vorangebracht hat.'
        />
        <meta itemProp='image' content={img4} />
      </Helmet>
      <div className='testimonial__text'>
        <h3 className='text--sm testimonial__text-heading'>
          <FormattedMessage
            id='testimonial.testimonials'
            defaultMessage='Testimonials'
          />
        </h3>
        <h2 className='testimonial__text-title text--xxl'>
          <FormattedMessage
            id='testimonial.success_stories'
            defaultMessage='Success Stories'
          />
        </h2>
        <h2
          className='testimonial__text-title text--xxl testimonial__text-title--elite'
          ref={testimonialRef}
        >
          <FormattedMessage
            id='testimonial.the_elite'
            defaultMessage='DIE ELITE'
          />
        </h2>
      </div>
      <div className='testimonial__container'>
        <InfoCard
          img={laura}
          name='Laura Hildebrand'
          pos={
            <FormattedMessage
              id='testimonial.lauraPos'
              defaultMessage='Cyber Security Consultant at EY'
            />
          }
          text={
            <FormattedMessage
              id='testimonial.laura'
              defaultMessage='Das Studium in Kombination mit Praxiserfahrung hat mir ermöglicht, mich in verschiedene Richtungen zu entwickeln, die mich in meinem Berufsleben sehr geprägt haben.'
            />
          }
        />
        <InfoCard
          img={hendrik}
          name='Hendrik Braukmüller'
          pos={
            <FormattedMessage
              id='testimonial.hendrikPos'
              defaultMessage='IT Inhouse Consultant at Döhler Group'
            />
          }
          text={
            <FormattedMessage
              id='testimonial.hendrik'
              defaultMessage='Als IT Inhouse Consultant bei Döhler profitiere ich heute noch von meinen IMBIT-Erfahrungen. Vieles des Erlernten wende ich tagtäglich in meiner Arbeit an.'
            />
          }
        />
        <InfoCard
          img={img4}
          name='Raymond Segun'
          pos={
            <FormattedMessage
              id='testimonial.raymondPos'
              defaultMessage='Business Consultant at EY'
            />
          }
          text={
            <FormattedMessage
              id='testimonial.raymond'
              defaultMessage='Die Mischung aus Theorie und Praxis im IMBIT-Studiengang ermöglicht ein breites Verständnis in den Bereichen IT, Finance und International Management.'
            />
          }
        />
        <InfoCard
          img={meike}
          name='Meike Bremmer'
          pos={
            <FormattedMessage
              id='testimonial.meikePos'
              defaultMessage='Agile Specialist at Boehringer Ingelheim'
            />
          }
          text={
            <FormattedMessage
              id='testimonial.meike'
              defaultMessage='Während meines IMBIT-Studiums konnte ich wertvolle Auslandserfahrungen in Spanien, Kanada und Singapur sammeln. Damit habe ich mir im weiteren Verlauf meines Leben viele Türen geöffnet.'
            />
          }
        />
      </div>
    </div>
  );
};

/**
 * InfoCard component to display individual testimonial information.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.img - The image source URL.
 * @param {string} props.name - The name of the individual.
 * @param {React.ReactNode} props.pos - The position of the individual.
 * @param {React.ReactNode} props.text - The testimonial text.
 * @returns {JSX.Element} The rendered InfoCard component.
 */
const InfoCard = ({ img, name, pos, text }) => {
  return (
    <div className='testimonial__card'>
      <div className='testimonial__card-header'>
        <img
          src={img}
          alt={`${name}'s portrait`}
          className='testimonial__card-image'
          loading='lazy'
        />
        <section className='testimonial__card-text'>
          <h4 className='testimonial__card-text--name'>{name}</h4>
          <p className='testimonial__card-text--position'>{pos}</p>
        </section>
      </div>
      <p className='testimonial__card-text--position'>{text}</p>
    </div>
  );
};

export default Testimonial;
