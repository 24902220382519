/* eslint-disable no-console */
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import ChatbotScript from './components/general/ChatBot.js';
import FaviconSwitcher from './components/general/FaviconSwitcher.js';
import Footer from './components/general/Footer.js';
import {
  LanguageProvider,
  useLanguage,
} from './components/general/LanguageSelector.js';
import Navbar from './components/general/Navbar.js';
import NotFound from './components/general/NotFound.js';
import './components/general/styles/design/color.css';
import './components/general/styles/design/shadow.css';
import './components/general/styles/design/spacing.css';
import './components/general/styles/design/typography.css';
import enTranslations from './components/general/translations/en.json';
import esTranslations from './components/general/translations/es.json';
import './index.css';
import AuslandPage from './pages/AuslandPage.js';
import IMBIT from './pages/IMBIT.js';
import Impressum from './pages/Impressum.js';
import TinBit from './pages/TinBit.js';

const App = () => {
  const { selectedLanguage } = useLanguage();
  const location = useLocation();

  // Define the messages object here, mapping locales to their respective translations
  const messages = {
    en: enTranslations,
    es: esTranslations,
    de: {}, // Assuming you have German translations, you should include them here
  };

  const handleIntlError = (err) => {
    if (err.code === 'MISSING_TRANSLATION' && selectedLanguage === 'de') {
      // Ignore the missing translation error for German locale
    } else {
      // Handle other errors (e.g., log to an error reporting service)
      console.error(err);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <IntlProvider
      locale={selectedLanguage}
      messages={messages[selectedLanguage]}
      onError={handleIntlError}
    >
      <HelmetProvider>
        <Helmet>
          <meta charSet='utf-8' />
          <link href='https://fonts.googleapis.com' />
          <link href='https://fonts.gstatic.com' crossOrigin='anonymous' />
          <meta name='viewport' content='width=device-width, initial-scale=1' />
        </Helmet>
        <FaviconSwitcher />
        <Navbar />
        <Routes>
          <Route path='/' element={<IMBIT />} />
          <Route path='/Ausland' element={<AuslandPage />} />
          <Route path='/TinBit' element={<TinBit />} />
          <Route path='/Impressum' element={<Impressum />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
        <ChatbotScript />
        <Footer />
      </HelmetProvider>
    </IntlProvider>
  );
};

const RootApp = () => (
  <LanguageProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </LanguageProvider>
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RootApp />
  </React.StrictMode>,
);
