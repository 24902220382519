import {
  motion,
  useAnimation,
  useMotionValue,
  useTransform,
} from 'framer-motion';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import './style/index.css'; // Import CSS file

/**
 * SwipeCard Component
 *
 * @param {Object} props - Component properties
 * @param {React.Component} props.FrontComponent - Front side component of the swipe card
 * @param {React.Component} props.BackComponent - Back side component of the swipe card
 * @param {Object} props.item - The data item to be displayed on the card
 * @param {number} props.index - The index of the card in the list
 * @param {Function} props.onSwipe - Function called when a card is swiped
 * @param {boolean} props.showOverlay - Boolean indicating if the green/red overlay should be shown
 * @param {boolean} props.isReturning - Boolean indicating if the card is returning to the deck
 * @param {number} props.arrayLength - The total number of cards in the array
 * @param {number} props.position - The position of the card in the array
 * @param {boolean} props.showStart - Boolean indicating if the start screen is shown
 * @param {boolean} props.isFavoritesOpen - Boolean indicating if the favorites section is open
 * @param {boolean} props.isToggled - Boolean indicating if the card switch is toggled
 *
 * @returns {JSX.Element} SwipeCard component
 */
const SwipeCard = forwardRef(
  (
    {
      FrontComponent,
      BackComponent,
      item,
      index,
      onSwipe,
      showOverlay,
      isReturning,
      arrayLength,
      position,
      showStart,
      isFavoritesOpen,
      isToggled,
    },
    ref,
  ) => {
    const [flipped, setFlipped] = useState(false);
    const [delayedFlipped, setDelayedFlipped] = useState(false);
    const x = useMotionValue(0);
    const rotate = useTransform(x, [-200, 200], [-20, 20]);
    const backgroundColor = useTransform(
      x,
      [-200, 0, 200],
      showOverlay
        ? [
            'rgba(226, 93, 71, 0.8)',
            'rgba(255, 255, 255, 0)',
            'rgba(75, 212, 68, 0.8)',
          ]
        : [
            'rgba(255, 255, 255, 0)',
            'rgba(255, 255, 255, 0)',
            'rgba(255, 255, 255, 0)',
          ],
    );
    const controls = useAnimation();

    /**
     * Handle more info button click to flip the card to the back.
     */
    const handleMoreInfo = () => {
      if (index === 0) {
        setFlipped(true);
        setTimeout(() => {
          setDelayedFlipped(true);
        }, 200);
      }
    };

    /**
     * Handle back button click to flip the card to the front.
     */
    const handleBack = () => {
      if (index === 0) {
        setFlipped(false);
        setTimeout(() => {
          setDelayedFlipped(false);
        }, 200);
      }
    };

    /**
     * Swipe the card to the right.
     */
    const swipeRight = () => {
      if (index === 0) {
        controls
          .start({ x: 1000, opacity: 0, transition: { duration: 0.4 } })
          .then(() => {
            onSwipe(item, 'right');
          });
      }
    };

    /**
     * Swipe the card to the left.
     */
    const swipeLeft = () => {
      if (index === 0) {
        controls
          .start({ x: -1000, opacity: 0, transition: { duration: 0.4 } })
          .then(() => {
            onSwipe(item, 'left');
          });
      }
    };

    /**
     * Handle the end of a drag event to determine swipe direction.
     */
    const handleDragEnd = (event, info) => {
      if (index === 0) {
        if (info.offset.x > 150) {
          swipeRight();
        } else if (info.offset.x < -150) {
          swipeLeft();
        } else {
          controls.start({ x: 0, opacity: 1, transition: { duration: 0.3 } });
        }
      }
    };

    useEffect(() => {
      if (index === 0) {
        // Transition for the card moving to the front
        controls.start({
          top: index * 20,
          scale: 1,
          transition: { duration: 0.2, ease: 'backOut' },
        });
      } else {
        // Transition for cards moving from back to front
        controls.start({
          top: index * 20,
          scale: 1 - index * 0.05,
          transition: { duration: 0.3, ease: 'easeIn' },
        });
      }
    }, [index, controls]);

    useEffect(() => {
      if (isReturning) {
        // Animate the card coming back
        controls.start({
          x: 0,
          opacity: 1,
          transition: { duration: 0.5, ease: 'easeOut' },
        });
      }
    }, [isReturning, controls]);

    useImperativeHandle(ref, () => ({
      swipeRight,
      swipeLeft,
    }));

    /**
     * Handle key press events to swipe cards or flip them.
     * Only the top card should respond to key presses.
     * Left arrow key: swipe left
     * Right arrow key: swipe right
     * Space key: flip the card
     */
    useEffect(() => {
      const handleKeyPress = (event) => {
        if (index !== 0 || showStart || isFavoritesOpen || isToggled) return; // Only the top card should respond to key presses
        switch (event.key) {
          case 'ArrowLeft':
            swipeLeft();
            break;
          case 'ArrowRight':
            swipeRight();
            break;
          case ' ':
            if (flipped) {
              handleBack();
            } else {
              handleMoreInfo();
            }
            break;
          default:
            break;
        }
      };

      window.addEventListener('keydown', handleKeyPress);
      return () => {
        window.removeEventListener('keydown', handleKeyPress);
      };
    }, [index, flipped, showStart, isFavoritesOpen, isToggled]);

    return (
      <motion.div
        className={`card-container ${flipped ? 'card-container--flipped' : ''}`}
        style={{
          x: index === 0 ? x : 0, // Only the top card can be dragged
          rotate,
          zIndex: 3 - index,
          transform: `scale(${1 - index * 0.06})`,
          transition: 'transform 0.1s ease-out, opacity 0.1s ease-out',
          position: 'absolute', // Position the cards absolutely within the relative container
          top: 0, // Align cards to the top
          left: 0, // Align cards to the left
        }}
        drag={index === 0 ? 'x' : false} // Only the top card is draggable
        dragConstraints={{ left: -150, right: 150 }}
        dragElastic={0.1}
        onDragEnd={handleDragEnd}
        animate={controls}
        initial={{ top: index * 20, scale: 1 - index * 0.05 }}
        whileTap={{ cursor: index === 0 ? 'grabbing' : 'default' }}
      >
        {showOverlay && (
          <motion.div
            className='card__overlay'
            style={{
              backgroundColor,
              transition: 'background-color 0.0001s ease',
            }}
          />
        )}
        <div className='card'>
          <div className='card__inner'>
            <div
              className={`card__side ${delayedFlipped ? 'card__side--back' : 'card__side--front'}`}
            >
              {delayedFlipped ? (
                /**
                 * BackComponent
                 *
                 * @param {Object} props - Component properties
                 * @param {Object} props.item - The data item to be displayed on the back side of the card
                 * @param {number} props.index - The index of the card in the list
                 * @param {Function} props.onBack - Function to handle back button click
                 */
                <BackComponent item={item} index={index} onBack={handleBack} />
              ) : (
                /**
                 * FrontComponent
                 *
                 * @param {Object} props - Component properties
                 * @param {Object} props.item - The data item to be displayed on the front side of the card
                 * @param {number} props.index - The index of the card in the list
                 * @param {Function} props.onMoreInfo - Function to handle more info button click
                 * @param {number} props.arrayLength - The total number of cards in the array
                 * @param {number} props.position - The position of the card in the array
                 */
                <FrontComponent
                  item={item}
                  index={index}
                  onMoreInfo={handleMoreInfo}
                  arrayLength={arrayLength}
                  position={position}
                />
              )}
            </div>
          </div>
        </div>
      </motion.div>
    );
  },
);

export default SwipeCard;
