import React from 'react';
import wave from '../general/media/shapes/grey-waves.svg';
import './styles/Ausland.css';

/**
 * Ausland component that represents the international opportunities section of the website.
 * It includes an image of decorative waves and will have more content added in the future.
 *
 * @returns {JSX.Element} The rendered Ausland component.
 */
const Ausland = () => {
  return (
    <div className='ausland padding-top'>
      {/* Uncomment and customize the Helmet component for SEO when ready */}
      {/* <Helmet>
        <meta
          name="description"
          content="Entdecken Sie die internationalen Möglichkeiten mit dem Studiengang IMBIT an der DHBW Mannheim. Erfahren Sie mehr über Summer Schools, Praxisphasen und Theoriephasen im Ausland."
        />
        <meta
          name="keywords"
          content="IMBIT, DHBW Mannheim, Ausland, Summer School, Praxisphase, Theoriephase, internationales Studium, Auslandsaufenthalt"
        />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://www.mannheim.dhbw.de/dual-studieren/bachelor/wirtschaft/wirtschaftsinformatik/imbit/ausland"
        />
      </Helmet>
      <div className="padding-container">
        <div className="ausland__content">
          <section className="ausland__text">
            <h2 className="ausland__text-heading">
              <FormattedMessage
                id="ausland.heading"
                defaultMessage="IMBIT im Ausland"
              />
            </h2>
            <p className="ausland__text-paragraph">
              <FormattedMessage
                id="ausland.description"
                defaultMessage="Entdecke die Welt mit IMBIT"
              />
            </p>
          </section>
        </div>
        <section className="ausland__selection">
          <Button
            className="ausland__selection-button"
            link="/Ausland#SummerSchool"
            defaultMessage="Summer School"
            id="ausland.summerSchool"
          />
          <Button
            className="ausland__selection-button"
            link="/Ausland"
            defaultMessage="Praxisphase im Ausland"
            id="ausland.internshipAbroad"
          />
          <Button
            className="ausland__selection-button"
            link="/Ausland"
            defaultMessage="Theoriephase im Ausland"
            id="ausland.studyAbroad"
          />
        </section>
      </div> */}
      <img
        src={wave}
        className='ausland__wave'
        alt='Decorative wave'
        loading='lazy'
      />
    </div>
  );
};

export default Ausland;
