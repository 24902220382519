/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */

// Importing libraries and resources
import { motion, useAnimation } from 'framer-motion';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import cardSwitchIcon from '../general/media/icons/cardSwitch.svg';
import companiesIcon from '../general/media/icons/companies.svg';
import courseIcon from '../general/media/icons/courses.svg';
import heartIcon from '../general/media/icons/heart.svg';
import helpCircleIcon from '../general/media/icons/helpCircle.svg';
import newTabIcon from '../general/media/icons/newTab.svg';
import alumniIcon from '../general/media/icons/user.svg';
import './style/menuTop.css';

/**
 * TopMenu Component
 *
 * @param {Object} props - Component properties
 * @param {Function} props.changeCardType - Function to change the type of card displayed
 * @param {Function} props.toggleCardSwitchParent - Function to toggle the card switch state in the parent component
 * @param {boolean} props.isToggled - Boolean indicating if the card switch is toggled
 * @param {Array} props.favorites - Array of favorite items
 * @param {Function} props.restartTinbit - Function to restart the Tinbit component
 * @param {Function} props.onHelpClick - Function triggered when the help button is clicked
 * @param {Function} props.toggleFavorites - Function to toggle the favorites section
 * @param {boolean} props.isFavoritesOpen - Boolean indicating if the favorites section is open
 * @param {Object} ref - Ref object for the component
 *
 * @returns {JSX.Element} TopMenu component
 */
const TopMenu = forwardRef(
  (
    {
      changeCardType,
      toggleCardSwitchParent,
      isToggled,
      favorites,
      restartTinbit,
      onHelpClick,
      toggleFavorites,
      isFavoritesOpen,
    },
    ref,
  ) => {
    // Initializing animations and state
    const controls = useAnimation();
    const [buttonsClass, setButtonsClass] = useState('buttonsContainer');

    // Function to toggle the card switch
    const toggleCardSwitch = () => {
      if (isFavoritesOpen) toggleFavorites();
      toggleCardSwitchParent();
    };

    // Using the ref object to make the toggleFavorites function available
    useImperativeHandle(ref, () => ({
      toggleFavorites,
    }));

    // Effect to start an animation when favorites change
    useEffect(() => {
      if (favorites.length > 0) {
        controls.start({
          scale: [1, 1.5, 1],
          transition: { duration: 0.5, times: [0, 0.5, 1] },
        });
      }
    }, [favorites, controls]);

    // Effect to change the button classes based on state
    useEffect(() => {
      let timer;
      if (!isToggled && !isFavoritesOpen) {
        timer = setTimeout(() => {
          setButtonsClass('buttonsContainer');
        }, 700);
      } else {
        setButtonsClass('buttonsContainer buttonsContainer__toggled');
      }
      return () => clearTimeout(timer);
    }, [isToggled, isFavoritesOpen]);

    // Rendering the component
    return (
      <div className='topMenu'>
        <div className={buttonsClass}>
          <div
            onClick={toggleCardSwitch}
            className={
              isToggled ? 'toggleButton toggleButton__pressed' : 'toggleButton'
            }
            role='button'
            tabIndex={0}
          >
            <img src={cardSwitchIcon} alt='card switch icon' />
            <p>
              <FormattedMessage id='tinbit.switch' defaultMessage='Wechseln' />
            </p>
          </div>
          <img
            className='topMenu--helpButton'
            src={helpCircleIcon}
            onClick={onHelpClick}
            alt='Help icon'
            role='button'
            tabIndex={0}
          />
          <div
            onClick={toggleFavorites}
            className={
              isFavoritesOpen
                ? 'toggleButton toggleButton__pressed'
                : 'toggleButton'
            }
            role='button'
            tabIndex={0}
          >
            <motion.img
              animate={controls}
              className='toggleButton__heart'
              alt='heart'
              src={heartIcon}
            />
            <p>
              <FormattedMessage
                id='tinbit.favorites'
                defaultMessage='Favoriten'
              />
            </p>
          </div>
        </div>

        <motion.div
          className='cardSwitchContainer'
          initial={{
            y: isToggled ? 7 : -10,
            opacity: isToggled ? 1 : 0,
            display: isToggled ? 'block' : 'none',
          }}
          animate={{
            y: isToggled ? 7 : -10,
            opacity: isToggled ? 1 : 0,
            display: isToggled ? 'block' : 'none',
          }}
          transition={{ duration: 0.3, type: 'spring', stiffness: 55 }}
        >
          <div className='cardSwitch'>
            <button
              className='cardSwitch__button'
              onClick={() => changeCardType('companies')}
              type='button'
            >
              <img src={companiesIcon} alt='companies icon' />
              <FormattedMessage
                id='tinbit.companies'
                defaultMessage='Unternehmen'
              />
            </button>
            <button
              className='cardSwitch__button'
              onClick={() => changeCardType('lecturers')}
              type='button'
            >
              <img src={courseIcon} alt='lecturers icon' />
              <FormattedMessage
                id='tinbit.lecturers'
                defaultMessage='Dozenten'
              />
            </button>
            <button
              className='cardSwitch__button'
              onClick={() => changeCardType('alumni')}
              type='button'
            >
              <img src={alumniIcon} alt='alumni icon' />
              <FormattedMessage id='tinbit.alumni' defaultMessage='Alumni' />
            </button>
          </div>
        </motion.div>

        <motion.div
          className='favoritesContainer'
          initial={{
            y: isFavoritesOpen ? 7 : -20,
            opacity: isFavoritesOpen ? 1 : 0,
            display: isFavoritesOpen ? 'block' : 'none',
          }}
          animate={{
            y: isFavoritesOpen ? 7 : -20,
            opacity: isFavoritesOpen ? 1 : 0,
            display: isFavoritesOpen ? 'block' : 'none',
          }}
          transition={{ duration: 0.7, type: 'spring', stiffness: 45 }}
        >
          <div className='favorites__items'>
            {favorites.length === 0 ? (
              <div className='favorites__empty'>
                <FormattedMessage
                  id='tinbit.favoritesDefault'
                  defaultMessage='Noch keine Unternehmen gemerkt'
                />
              </div>
            ) : (
              favorites.map(
                (item) =>
                  item &&
                  item.image && (
                    <div key={item.id} className='favorites__item'>
                      <div className='favorites__item-container'>
                        <img
                          src={item.logo}
                          alt={item.name}
                          className='favorites__item-image'
                        />
                        <div>
                          <h4 className='favorites__item-name'>{item.name}</h4>
                          <p className='favorites__item-description'>
                            {item.industry}
                          </p>
                        </div>
                      </div>
                      <div className='favorites__item-applyLink'>
                        <a
                          href={item.applyLink}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <img src={newTabIcon} alt='open in new tab' />
                        </a>
                      </div>
                    </div>
                  ),
              )
            )}
          </div>
          <div className='favorites__restartContainer'>
            <div className='favorites__buttonRestart' onClick={restartTinbit}>
              <FormattedMessage
                id='tinbit.restart'
                defaultMessage='Neustarten'
              />
            </div>
          </div>
        </motion.div>
      </div>
    );
  },
);

// Exporting the TopMenu component
export default TopMenu;
