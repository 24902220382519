/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
import './styles/ImbitBestandteile.css';

/**
 * ImbitBestandteile component that describes the different modules of the IMBIT program.
 * It includes sections for International Management, Economics, and Informatics,
 * with additional information links.
 *
 * @returns {JSX.Element} The rendered ImbitBestandteile component.
 */
const ImbitBestandteile = () => {
  return (
    <div className='course-details padding-container padding-top'>
      <Helmet>
        <meta
          name='description'
          content='Entdecken Sie die Module des Studiengangs International Management for Business and Information Technology (IMBIT) an der DHBW Mannheim. Erfahren Sie mehr über International Management, Wirtschaft und Informatik.'
        />
        <meta
          name='keywords'
          content='IMBIT, DHBW Mannheim, International Management, Wirtschaft, Informatik, Studiengang, Module, Intercultural Management, IT-Management, Unternehmensführung, BWL, Marketing, Finanzbuchhaltung, Recht, VWL, Programmierung, Datenbanken, Wirtschaftsinformatik'
        />
        <meta name='robots' content='index, follow' />
        <link
          rel='canonical'
          href='https://www.mannheim.dhbw.de/dual-studieren/bachelor/wirtschaft/wirtschaftsinformatik/imbit/studium'
        />
      </Helmet>
      <div className='course-details__wrapper'>
        <div className='module-card'>
          <div className='module-card__header'>
            <h3 className='text--xxl'>
              <FormattedMessage
                id='imbitBestandteile.moduleManagement'
                defaultMessage='International Management'
              />
            </h3>
          </div>
          <p className='module-card__description'>
            <FormattedMessage
              id='imbitBestandteile.moduleManagementDescription'
              defaultMessage='In Modulen wie Intercultural Management, IT-Management und Unternehmensführung erwirbst du Kompetenzen um internationale Teams zu führen!'
            />
          </p>
        </div>
        <div className='module-card'>
          <div className='module-card__header'>
            <h3 className='text--xxl'>
              <FormattedMessage
                id='imbitBestandteile.economics'
                defaultMessage='Wirtschaft'
              />
            </h3>
          </div>
          <p className='module-card__description'>
            <FormattedMessage
              id='imbitBestandteile.economicsDescription'
              defaultMessage='Wirtschaftskenntnisse erlernst du in BWL, Marketing, Finanzbuchhaltung & interner Kostenrechnung, Recht und VWL!'
            />
          </p>
        </div>

        <div className='module-card'>
          <div className='module-card__header'>
            <h3 className='text--xxl'>
              <FormattedMessage
                id='imbitBestandteile.informatics'
                defaultMessage='Informatik'
              />
            </h3>
          </div>
          <p className='module-card__description'>
            <FormattedMessage
              id='imbitBestandteile.informaticsDescription'
              defaultMessage='Für ein breites IT-Verständnis besuchst du Vorlesungen zu Programmierung, IT-Grundlagen, Datenbanken, Wirtschaftsinformatik und vielen mehr!'
            />
          </p>
        </div>
      </div>
      <div className='additional-info'>
        <h2 className='additional-info__title'>
          <FormattedMessage
            id='imbitBestandteile.moreInformation'
            defaultMessage='Für mehr Informationen:'
          />
        </h2>
        <ul className='additional-info__list'>
          <li
            className='additional-info__item'
            id='imbitBestandteile.moduleOverview'
          >
            <FormattedMessage
              id='imbitBestandteile.moduleOverview'
              defaultMessage='Finde eine Übersicht '
            />
            <a
              className='additional-info__link'
              href='https://www.mannheim.dhbw.de/dual-studieren/bachelor/wirtschaft/wirtschaftsinformatik/imbit/studium'
              target='_blank'
              rel='noreferrer'
            >
              <FormattedMessage
                id='imbitBestandteile.moduleOverviewLink'
                defaultMessage='aller Module'
              />
            </a>
          </li>
          <li className='additional-info__item'>
            <FormattedMessage
              id='imbitBestandteile.visitMetaverseFirst'
              defaultMessage='Besuche unseren '
            />
            <a
              className='additional-info__link'
              href='https://www.spatial.io/s/IMBIT-Expo-636a219beea9af00012a5956?share=324222080615529969'
              target='_blank'
              rel='noreferrer'
            >
              <FormattedMessage
                id='imbitBestandteile.visitMetaverseLink'
                defaultMessage='IMBIT-Ausstellungsraum'
              />
            </a>
            <FormattedMessage
              id='imbitBestandteile.visitMetaverseSecond'
              defaultMessage=' im Metaverse'
            />
          </li>
          <li className='additional-info__item'>
            <FormattedMessage
              id='imbitBestandteile.moduleDescriptions'
              defaultMessage='Lese ausführliche Modulbeschreibungen in unserem '
            />
            <a
              className='additional-info__link'
              href='https://www.dhbw.de/fileadmin/user/public/SP/MA/Wirtschaftsinformatik/International_Management_for_Business_and_Information_Technology.pdf'
              target='_blank'
              rel='noreferrer'
            >
              <FormattedMessage
                id='imbitBestandteile.moduleDescriptionsLink'
                defaultMessage='Modulhandbuch'
              />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ImbitBestandteile;
