import React, { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import AuslandPraxis from '../components/ausland/AuslandPraxis.js';
import TheorieAusland from '../components/ausland/TheorieAusland.js';

const SummerSchool = lazy(
  () => import('../components/ausland/SummerSchool.js'),
);
const MapNew = lazy(() => import('../components/ausland/MapNew.js'));

const AuslandPage = () => {
  return (
    <div>
      <Helmet>
        <title>Ausland | Erfahrungsberichte und Eindrücke</title>
      </Helmet>
      <AuslandPraxis />
      <TheorieAusland />
      <Suspense fallback={<div>Loading MapNew...</div>}>
        <MapNew />
      </Suspense>
      <Suspense fallback={<div>Loading SummerSchool...</div>}>
        <SummerSchool />
      </Suspense>
    </div>
  );
};

export default AuslandPage;
