import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import './styles/NotFound.css';

const NotFound = React.memo(() => {
  useEffect(() => {
    // Setze den body overflow-y: hidden wenn die Komponente gemountet wird
    document.body.style.overflow = 'hidden';

    const footer = document.getElementsByClassName('footer')[0];

    if (footer) {
      footer.style.position = 'absolute';
      footer.style.zIndex = '-5';
      footer.style.bottom = '0';
    }

    // Entferne den Stil wenn die Komponente unmounted wird
    return () => {
      document.body.style.overflow = 'auto';

      if (footer) {
        footer.style.position = 'relative';
        footer.style.zIndex = '0';
      }
    };
  }, []);

  return (
    <div>
      <Helmet>
        <meta charset='utf-8' />
        <link rel='preconnect' href='https://fonts.googleapis.com' />
        <link rel='preconnect' href='https://fonts.gstatic.com' crossOrigin />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <title>404 - Not Found</title>
      </Helmet>
      <div className='padding-top padding-container'>
        <h1 className='notfound__heading'>Oops! You seem to be lost.</h1>
        <p className='notfound__paragraph'>This page does not exist</p>
      </div>
    </div>
  );
});

export default NotFound;
