import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
import Button from '../general/Button.js';
import bottomWave from '../general/media/shapes/blue-wave.svg';
import './styles/WieDual.css';

/**
 * WieDual component displays information about dual study programs and provides
 * buttons to take a test to find out if a dual study is suitable and to learn about partner companies.
 *
 * @returns {JSX.Element} The rendered WieDual component.
 */
const WieDual = () => {
  return (
    <div className='wie-dual padding-top'>
      <Helmet>
        <meta
          name='description'
          content='Finden Sie heraus, ob ein duales Studium das Richtige für Sie ist und lernen Sie unsere Partnerunternehmen kennen.'
        />
        <meta
          name='keywords'
          content='duales Studium, Studiengang, Stärken und Schwächen, Partnerunternehmen, Karriere'
        />
        <meta
          property='og:title'
          content='Wie dual bist du? - Duale Studiengänge'
        />
        <meta
          property='og:description'
          content='Finden Sie heraus, ob ein duales Studium das Richtige für Sie ist und lernen Sie unsere Partnerunternehmen kennen.'
        />
        <meta property='og:url' content='https://yourwebsite.com/wiedual' />
        <meta
          name='twitter:title'
          content='Wie dual bist du? - Duale Studiengänge'
        />
        <meta
          name='twitter:description'
          content='Finden Sie heraus, ob ein duales Studium das Richtige für Sie ist und lernen Sie unsere Partnerunternehmen kennen.'
        />
        <meta name='twitter:card' content='summary_large_image' />
      </Helmet>
      <div className='wie-dual__black-shape'>
        <div className='wie-dual__text padding-container'>
          <div className='wie-dual__text-container'>
            <h2 className='text--lg'>
              <FormattedMessage
                id='wieDual.howDualAreYou'
                defaultMessage='Wie dual bist du?'
              />
            </h2>
            <p>
              <FormattedMessage
                id='wieDual.questionDescription'
                defaultMessage='Du bist dir nicht sicher, ob du dual studieren möchtest? Welcher Studiengang zu dir passt? Wo deine Stärken und Schwächen liegen? Unsere Fragen helfen dir Antworten auf deine Fragen zu finden!'
              />
            </p>
            <Button
              link='https://brillianideas.com/content/quiz.html'
              target='_blank'
              defaultMessage='MACH DEN TEST'
              className='wie-dual__button'
              id='wieDual.takeTest'
            />
          </div>
          <div className='wie-dual__text-container'>
            <h2 className='text--lg'>
              <FormattedMessage
                id='wieDual.yourDualPartners'
                defaultMessage='Deine dualen Partner'
              />
            </h2>
            <p>
              <FormattedMessage
                id='wieDual.partnerDescription'
                defaultMessage='Nutze unser selbst entwickeltes Swiping-Tool TinBit, um unsere Partnerunternehmen kennenzulernen. Swipe dich jetzt durch die Unternehmensprofile und finde deinen zukünftigen Arbeitgeber!'
              />
            </p>
            <Button
              link='/TinBit'
              target=''
              defaultMessage='SWIPE IN TINBIT'
              className='wie-dual__button'
              id='wieDual.swipeTinbit'
            />
          </div>
          {/* <div className="video-container">
              <video
                className="wiedual-picture"
                autoplay="autoplay"
                muted="muted"
                loop="loop"
              >
                <source src={imbitVorteile} type="video/mp4" />
              </video>
          </div> */}
        </div>
        <img
          src={bottomWave}
          alt='Decorative Wave'
          className='wie-dual__bottom-shape'
          loading='lazy'
        />
      </div>
    </div>
  );
};

export default WieDual;
