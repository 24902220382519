import PropTypes from 'prop-types'; // Import PropTypes for prop type validation
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import flagEN from './media/icons/englishflag.webp';
import flagDE from './media/icons/germanflag.png';
import flagES from './media/icons/spanishflag.png';
import './styles/LanguageSelector.css';

// Create a context for managing language selection
const LanguageContext = createContext();

/**
 * LanguageProvider component to manage and provide the selected language state.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components that will be wrapped by the provider.
 *
 * @returns {JSX.Element} The LanguageProvider component.
 */
export const LanguageProvider = ({ children }) => {
  // State to store the selected language, initialized from localStorage
  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    return localStorage.getItem('selectedLanguage') || 'de';
  });

  // Effect to handle changes in localStorage and update the selected language
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'selectedLanguage') {
        const newLanguage = event.newValue || 'de';
        setSelectedLanguage(newLanguage);
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  /**
   * Function to change the selected language and update localStorage.
   *
   * @param {string} language - The new selected language.
   */
  const handleChange = (language) => {
    setSelectedLanguage(language);
    localStorage.setItem('selectedLanguage', language);
  };

  // Memoized context value to avoid unnecessary re-renders
  const value = useMemo(
    () => ({ onChange: handleChange, selectedLanguage }),
    [handleChange, selectedLanguage],
  );

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

// Define prop types for LanguageProvider
LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * Custom hook to use the language context.
 *
 * @returns {Object} The context value containing the current language and the change handler.
 *
 * @throws Will throw an error if used outside of a LanguageProvider.
 */
export function useLanguage() {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
}

/**
 * LanguageSelector component to display and change the selected language.
 *
 * @returns {JSX.Element} The rendered LanguageSelector component.
 */
const LanguageSelector = () => {
  // Use the language context
  const { onChange, selectedLanguage } = useLanguage();

  // State to manage the visibility of the language options dropdown
  const [isOptionsVisible, setOptionsVisible] = useState(false);

  // Ref to access the dropdown DOM element
  const dropdownRef = useRef();

  // Get the current location to determine if the page is the Impressum page
  const location = useLocation();
  const isImpressumPage = location.pathname === '/Impressum';

  // Effect to handle clicks outside the dropdown and close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOptionsVisible(false);
      }
    };

    const handleTouchOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOptionsVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleTouchOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleTouchOutside);
    };
  }, []);

  /**
   * Toggles the visibility of the language options dropdown.
   *
   * @param {Event} event - The click or touch event.
   */
  const toggleOptions = (event) => {
    if (window.innerWidth <= 768) {
      event.stopPropagation();
      setOptionsVisible(!isOptionsVisible);
    }
  };

  /**
   * Handles the selection of a language.
   *
   * @param {string} value - The selected language.
   */
  const handleLanguageSelect = (value) => {
    onChange(value);
    setOptionsVisible(false);
  };

  // Effect to update the document language based on the selected language
  useEffect(() => {
    document.documentElement.lang = isImpressumPage ? 'de' : selectedLanguage;
  }, [isImpressumPage, selectedLanguage]);

  return (
    <>
      <Helmet>
        <html lang={isImpressumPage ? 'de' : selectedLanguage} />
      </Helmet>
      {!isImpressumPage && (
        <div
          className='language-selector'
          ref={dropdownRef}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className='language-selector__selected'
            onClick={toggleOptions}
            onTouchStart={toggleOptions}
          >
            <div className='language-selector__flag-container'>
              {selectedLanguage === 'de' && (
                <img
                  src={flagDE}
                  alt='German Flag'
                  className='language-selector__flag'
                />
              )}
              {selectedLanguage === 'en' && (
                <img
                  src={flagEN}
                  alt='English Flag'
                  className='language-selector__flag'
                />
              )}
              {selectedLanguage === 'es' && (
                <img
                  src={flagES}
                  alt='Spanish Flag'
                  className='language-selector__flag'
                />
              )}
            </div>
          </div>
          {(isOptionsVisible || window.innerWidth > 768) && (
            <div className='language-selector__options'>
              <div
                className='language-selector__option'
                onClick={() => handleLanguageSelect('de')}
                onTouchStart={() => handleLanguageSelect('de')}
              >
                <img
                  src={flagDE}
                  alt='German Flag'
                  className='language-selector__flag'
                />
              </div>
              <div
                className='language-selector__option'
                onClick={() => handleLanguageSelect('en')}
                onTouchStart={() => handleLanguageSelect('en')}
              >
                <img
                  src={flagEN}
                  alt='English Flag'
                  className='language-selector__flag'
                />
              </div>
              <div
                className='language-selector__option'
                onClick={() => handleLanguageSelect('es')}
                onTouchStart={() => handleLanguageSelect('es')}
              >
                <img
                  src={flagES}
                  alt='Spanish Flag'
                  className='language-selector__flag'
                />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export { LanguageSelector };
