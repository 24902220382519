import React, { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import AbiFertig from '../components/landing/AbiFertig.js';
import Ausland from '../components/landing/Ausland.js';
import EndVideo from '../components/landing/EndVideo.js';
import ImbitBestandteile from '../components/landing/ImbitBestandteile.js';
import Statistics from '../components/landing/Statistics.js';
import Testimonial from '../components/landing/Testimonial.js';
import VideoAndJourneyStart from '../components/landing/VideoAndJourneyStart.js';
import WieDual from '../components/landing/WieDual.js';

const Line = lazy(() => import('../components/landing/Line.js'));
const Metaverse = lazy(() => import('../components/landing/Metaverse.js'));
const Chat = lazy(() => import('../components/landing/Chat.js'));
const ImbitInfo = lazy(() => import('../components/landing/ImbitInfo.js'));

const IMBIT = () => {
  return (
    <div className='App'>
      <Helmet>
        <title>
          IMBIT Journey: Dein duales Studium in Wirtschaftsinformatik und
          internationalem Management
        </title>
      </Helmet>
      <VideoAndJourneyStart />
      <Suspense>
        <Line />
      </Suspense>
      <AbiFertig />
      <Statistics />
      <WieDual />
      <Suspense fallback={<div>Loading ImbitInfo...</div>}>
        <ImbitInfo />
      </Suspense>
      <ImbitBestandteile />
      <Ausland />
      <Suspense fallback={<div>Loading Chat...</div>}>
        <Chat />
      </Suspense>
      <Testimonial />
      <Suspense fallback={<div>Loading Metaverse...</div>}>
        <Metaverse />
      </Suspense>
      <EndVideo />
    </div>
  );
};
export default IMBIT;
