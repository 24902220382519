import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
import arrow from '../general/media/icons/arrow.svg';
import mobileHeroImage from './media/hero-image.webp';
import poster from './media/ImbitNextPoster.webp';
import wave from './media/shapes/red-wave-new.svg';
import imbit from './media/videos/IMBIT-N3XT.mp4';
import './styles/VideoAndJourneyStart.css';

/**
 * TitlePage component that displays a video and introductory text for IMBIT.
 * The video attempts to autoplay when it becomes visible in the viewport.
 *
 * @returns {JSX.Element} The rendered TitlePage component.
 */
const TitlePage = () => {
  const videoRef = useRef(null);
  const [autoplayFailed, setAutoplayFailed] = useState(false);

  useEffect(() => {
    const videoElement = videoRef.current;
    let observer;

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          videoElement.play().catch(() => setAutoplayFailed(true));
        } else {
          videoElement.pause();
        }
      });
    };

    if (videoElement) {
      // Wait for the video to be ready to play
      videoElement.oncanplaythrough = async () => {
        try {
          await videoElement.play();
        } catch (err) {
          setAutoplayFailed(true); // Autoplay failed, show controls
        }
      };

      observer = new IntersectionObserver(handleIntersection, {
        threshold: 0.5, // Adjust as needed
      });

      observer.observe(videoElement);
    }

    // Cleanup function
    return () => {
      if (videoElement) {
        videoElement.oncanplaythrough = null;
      }
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  return (
    <div className='title-page'>
      <Helmet>
        <link
          rel='preload'
          fetchPriority='high'
          as='image'
          href={wave}
          type='image/svg+xml'
        />
        <link
          rel='preload'
          href={mobileHeroImage}
          type='image/svg+xml'
          as='image'
        />
      </Helmet>
      <div className='title-page__main'>
        <div className='title-page__videoContainer'>
          <video
            ref={videoRef}
            className='title-page__video'
            loop
            muted
            controls={autoplayFailed} // Show controls if autoplay failed
            preload='auto'
            poster={poster} // Poster image displayed before video loads
          >
            <source src={imbit} type='video/mp4' />
          </video>
        </div>
        <div className='title-page__imageContainer'>
          <img
            className='title-page__image'
            src={mobileHeroImage}
            alt='mobile Hero'
          />
          <div className='title-page__heroText'>
            <h1>IMBIT</h1>
            <p>
              Dein dualer Start in <br />
              die Arbeitswelt.
            </p>
          </div>
        </div>
      </div>
      <div className='title-page__wave-container'>
        <img src={wave} alt='' role='presentation' />
        <div className='title-page__journey-begin'>
          <h1 className='title-page__journey-begin-text'>
            <FormattedMessage
              id='VideoAndJourneyStart.discoverJourney'
              defaultMessage='Entdecke die IMBIT Journey'
            />
          </h1>
          <img
            src={arrow}
            className='title-page__arrow-svg'
            alt=''
            role='presentation'
          />
        </div>
      </div>
    </div>
  );
};

export default TitlePage;
